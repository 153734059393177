/**
 * @file
 * @author  fangsimin
 * @date    2020-02-09 21:29:35
 * @last Modified by    fangsimin@baidu.com
 * @last Modified time  2021-06-25 15:32:10
 */

import state from './state-zhHant';
import country from './country-zhHant';
export default {
    permissions: {
        legalReTip: '公司註冊所在國家',
        legalPerson: '法人所在地',
        companyLegalPerson: '公司法人所在地',
        roleList: '角色清單',
        roleName: '角色名稱',
        rolePrivileges: '角色權限',
        announcementEmail: '資訊同步信箱'
    },
    newDashboradBreadCrumbs: {
        account: '主頁',
        accounts: '帳戶',
        companys: '公司',
        campaign: '廣告計劃',
        campaigns: '廣告計劃',
        ad: '廣告',
        billCampaign: '活动'
    },
    monthBill: {
        agencyAccountName: '代理商帳號名稱',
        monthBillTitle: '每月帳單',
        settlementCurrency: '帳戶當前結算金額',
        settlementCurrencyRemark: '賬戶當期含稅費用（交易幣種）按當月匯率和結算幣種金額計算',
        montlyExchangeRate: '每月匯率',
        settlementSpendWithTax: '賬戶當期含稅費用',
        spendPlusTax: '賬戶當期未稅費用',
        consumptionTax: '當期消費稅',
        consumptionTaxRemark: '賬戶當期未稅費用產生的消費稅',
        spendPlusTaxRemark: '所選月份的賬戶累計未稅花費',
        settlementSpendWithTaxRemark: '所選月份的帳戶含稅費用，賬戶當期含稅費用=賬戶當期未稅費用+賬戶當期消費稅',
        monthlyExchangeRate: '每月匯率',
        agentName: '代理商帳號名稱',
        updataTitile: '前月帳單將於次月3日更新',
        monthlyExchangeRateRemark: '匯率換算規則：匯率換算以上月日均匯率為準。 匯率基於The Federal Reserve System(美聯儲系統)<a href="http://www.federalreserve.gov/releases/h10/hist/default.htm">http://www.federalreserve.gov/releases/h10/hist/default.htm</a>'

    },
    menuData: {
        oppoSDK: 'oem 报表',
        oppoSDKTitle: 'oem 报表',
        popInCreativeMakerTitle: 'popIn Creative Maker',
        receivablesTitle: 'Discovery 應收帳款',
        receivables: 'Discovery 應收帳款',
        monthlyBill: '対外確定請求',
        monthlyBillTitle: '対外確定請求',
        msgCenter: '接收通知',
        notification: '通知中心',
        notificationTitle: '通知中心',
        monthBillTitle: '每月帳單',
        amConfigTitle: '運營配置',
        amConfig: '運營配置',
        readCreateTitle: '建立READ Code',
        discoveryReadCreateTitle: '建立READ Code',
        minBid: '為提升優化效果，目標出價不能低於{minBid}',
        brandReason: '此廣告無法在精選網站展示，請聯繫您的服務窗口',
        imgReason: 'NG理由：廣告圖片或標題不符合規範',
        campaignReason: 'NG理由：廣告連結頁不符合規範',
        textOnImageReason: '廣告素材違反媒體政策，請聯繫您的客戶經理獲取詳細信息。',
        msnRejectionReason: '該廣告創意因不符合部分媒體審核規則而被拒審',
        sexualRelatedReason: '該廣告創意因涉及性相關違規問題而被拒審',
        tobaccoProductsReason: '該廣告創意因涉及未成年人違規問題而被拒審',
        textOnImageMediaType: 'MSN 審核標籤過濾',
        tagReason: '廣告符合資格投放(推廣的產品廣告部分受限) - （{tagName}）',
        uploadWaring: 'The platform service will go under regular maintenance for 6 hours in the near future, ',
        uploadWaringMore: 'more.',
        uploadText: 'The platform service will go under regular maintenance for 6 hours, starting between 10:00 pm on January 15, 2022 to 4:00 am on January 16, 2022 in the EST time zone. We recommend planning your campaign management accordingly.<br><br>Converted Time Zones:<br><br>UTC+8: 11:00 am on January 16, 2022  to 5:00 pm on January 16, 2022<br>UTC+0:  3:00 am on January 16, 2022 to 9:00 am on January 16, 2022',
        multiDimension: '多維度數據報表',
        multiDimensionTitle: '多維度數據報表',
        wrongList: '你所批量添加的{wrongList}等域名不正確，不正確域名將無法生效',
        downList: '點擊此處下載文檔',
        domainSuccess: '你上傳的domain中有{correctLenght}個成功命中',
        searchSimilarBtnTitle: '批量審核',
        searchSimilar: '批量審核',
        stripeActionTitle: 'Stripe',
        PaymentManagement: '帳款管理',
        PaymentManagementTitle: '帳款管理',
        ROIAnalysisTitle: 'ROI報表',
        campaignTitle: '創建廣告',
        dashboardTitle: '管理中心',
        new_dashboardTitle: '新管理中心',
        auditTitle: '審核廣告',
        ecommerceToolTitle: '電商工具',
        lPManagementTitle: '內容管理',
        oPManagementTitle: 'OP管理',
        productManagementTitle: '產品管理',
        domainManagementTitle: '域名管理',
        testTitle: '測試',
        visualTestTitle: '可視化測試',
        visualTestBannerTitle: 'Banner可視化測試',
        generateLocalPageWrapper: '生成落地頁網頁',
        generateLocalPageWrapperTitle: '生成落地頁網頁',
        checkStatusTitle: '廣告召回狀態',
        administrator: '管理員',
        financialTitle: '財務管理',
        monthlyBillRelease: 'Monthly Bill Release',
        monthlyBillSave: 'Monthly Bill Save',
        creditLineTitle: '財務管理',
        roiMgrTitle: '財務報表',
        incomeTitle: '應收款',
        payTitle: '應付款',
        offlineTitle: '離線管理',
        applyTitle: '',
        agencyManagementTitle: '代理商管理',
        permissionTitle: '權限管理',
        accountTitle: '賬户管理',
        userTitle: '用户權限',
        bdOpenAccountTitle: 'BD開户',
        roleTitle: '角色管理',
        balance: '總可用額度',
        adListTitle: '廣告',
        dataReportTitle: '數據報表',
        amTitle: '運營平台',
        amDashboardTitle: '運營分析',
        icafeTitle: '事項追蹤',
        sampleTitle: '抽樣平台',
        adTooltipText: '你至少選擇一條廣告計劃',
        pixel: '轉換追蹤',
        pixelTitle: '轉換追蹤',
        pixelConversion: '轉化',
        conversionListTitle: 'Conversion & Read',
        readListTitle: 'Read',
        conversionCreateTitle: '創建轉化',
        pixelCreate: '創建轉化',
        account: '個賬户',
        accounts: '個賬户',
        rechargeTitle: '賬户充值',
        payActionTitle: '充值',
        stripeAction: 'stripe',
        showbilling: 'stripe自動儲值',
        helpCenterTitle: '幫助中心',
        gettingStartTitle: '開始',
        createAndManageCampaignTitle: '廣告活動管理',
        priceAndBillTitle: '出價&儲值',
        qaCenterTitle: '內部常見問題',
        faqTitle: '常見問題',
        adPolicyTitle: '廣告政策',
        reportAndOptimizingTitle: '報告&優化',
        diagnosisTitle: '廣告診斷',
        bidAnalysisTitle: '競價分析',
        pageBidTitle: '廣告位出價配置',
        monthlyReportTitle: '運營月報',
        customerManagementTitle: '客户管理',
        wraningWrod: '請注意：目標出價高於正常範圍',
        cpcWraningWrod: 'CPC由{cpc}調整為{cpcData}調整幅度超過正常水平是否繼續',
        dayWraningWrod: '請注意：您將日預算由{day}調整到{dayData}，調整幅度超出正常水平，請確認是否繼續進行？',
        conversions: 'Pixel Conversion',
        conversionsTitle: 'Pixel Conversion',
        postback: 'S2S Postback',
        postbackTitle: 'S2S Postback',
        siteTip: '廣告活動下的部分網站費用低於{siteCpc}，將會自動調整為{siteCpc}',
        cpcTips: '請注意，此處為帳戶交易幣種',
        cpcTips2: ''
    },
    login: {
        emailLogin: '使用信箱登錄',
        thirdPartyLogin: '第三方賬户登錄',
        forgetPwd: '忘記密碼',
        haveNoAccount: '還沒有賬號？',
        register: '點擊註冊',
        email: '信箱',
        pwd: '密碼',
        loginText: '登錄',
        pwdBefore: '原密碼',
        pwdAfter: '新密碼',
        confirmPwd: '確認密碼',
        account: '賬號',
        loginEmail: '登錄信箱'

    },
    button: {
        yes: '是',
        no: '否',
        subimtAudit: '提交審核',
        subimtAuditTitle: '(審查時間需1至3個工作日)',
        backCheck: '返回检查',
        confrimReject: '确认拒审',
        search: 'Search',
        addCreative: '新增素材',
        trash: '刪除',
        reachOfLandingPage: 'Landing Page 到達率',
        checkoutConversion: '次要目標轉換率',
        budgetSpend: '預算消耗率',
        offerConversion: 'Offer轉化率',
        purchaseConversion: '主要目標轉換率',
        apiToken: '複製ApiToken',
        filterCols: '設置列',
        export: '導出',
        reset: '重置',
        apply: '應用',
        confirm: '確認',
        cancel: '取消',
        previous: '上一頁',
        next: '下一頁',
        submit: '提交',
        audit: '申訴',
        revise: '修改廣告計劃',
        total: '共',
        edit: '編輯',
        operate: '操作',
        detail: '詳情',
        authorize: '授權',
        adminAuthorize: 'Admin權限授權',
        advertiseAuthorize: '僅廣告主角色授權',
        ignore: '忽略',
        add: '新增',
        query: '查詢',
        recreate: '重新創建廣告計劃',
        createConversion: '創建轉化',
        createCampaign: '創建廣告',
        de: '德國',
        fr: '法國',
        it: '意大利',
        es: '西班牙',
        at: '奥地利',
        deLang: '德語',
        itLang: '意大利语',
        frLang: '法语',
        UpdateConversion: '更新轉化配置',
        archived: '歸檔',
        editMore: '編輯更多',
        delete: '刪除',
        pass: '通過',
        copy: '複製',
        import: '導入',
        create: '建立',
        history: '歷史記錄',
        pageAudit: '審核',
        preview: '預覽',
        rename: '重命名',
        sendReport: '發送報告',
        save: '儲存',
        notSave: '不保存',
        openEditor: '打開編輯器',
        deleteTitle: '是否刪除',
        deletePage: '提示',
        innerStatus: '休眠狀態'
    },
    dashboard: {
        totalBalance: '總可用額度',
        balanceTip1: '部分帳戶總可用額度即將消耗完畢，請聯絡您的客戶經理進行儲值，否則將會影響廣告正常投放',
        balanceTip2: '部分帳戶總可用額度已經消耗完畢，請聯絡您的客戶經理進行儲值，否則將會影響廣告正常投放',
        balanceTip3: '部分帳戶總可用額度即將或已經消耗完畢，請聯絡您的客戶經理進行充值，否則將會影響廣告正常投放',
        totalBalanceTip1: '帳戶總可用額度即將消耗完畢，預計可用<span style="color:#e92754;">{num}</span>天，請聯絡您的客戶經理進行充值，否則將會影響廣告正常投放',
        totalBalanceTip2: '帳戶總可用額度已消耗完畢，請聯絡您的客戶經理進行儲值，否則將會影響廣告正常投放',
        balanceStatus: '餘額狀態',
        sufficientBalance: '餘額充足',
        lowBalance: '餘額較低',
        tightBalance: '餘額緊張',
        insufficientBalance: '餘額不足',
        totalBalanceTip: '總可用額度=累積充值+信用額度+coupon-累計花費。總可用額度並非帳戶實際資產，實際資產請以帳單為準',
        searchDetail: '查看詳情',
        collapseDetail: '收起詳情',
        smbLearningCompletedTip: '智能出價學習成功，不支援修改cpc',
        maxcvCpcTip: 'MaxCV模式，不支援修改cpc',
        siteBlockAccountContentTip: '請注意一旦您進行了某個帳戶層級的網域屏蔽操作，您將無法解除該帳戶下廣告層級的domain屏蔽。',
        siteBlockCampaignContentTip: '請注意一旦您進行了某個廣告計劃層級的網域屏蔽操作，您將無法在帳戶層級解除此domain屏蔽，必須在該廣告計劃層級解除此domain。',
        viewSelected: '查看所選',
        adReview: '{num} Ad selected',
        assetCampaign: '{num} Asset selected',
        assetReview: '{num} Campaign selected',
        accountReview: '{num} Account selected',
        campaignReview: '{num} Campaign selected',
        specifyCampaignTip1: 'Campaign ID 不正確',
        specifyCampaignTip2: '該活動已作為新活動生效一鍵繼承。請重新選擇活動',
        specifyCampaignTip3: '該活動過去6天的CV小於３筆，未能達成一鍵繼承的標準，請重新選擇活動',
        specifyCampaignTip4: '此活動未有過去14天CV大於（含）3筆的素材。請重新選擇活動',
        specifyCampaignTip5: 'Country、platform、OS、Browser設置不一致，系統學習效果可能不佳。請問要繼續進行下一步嗎？',
        assignMatch: '手動指定',
        inheritCampaignId: '광고 캠페인 ID',
        inherit: '一鍵複製',
        matchType: '匹配方式',
        autoMatch: '自動匹配',
        exceedLimitTip: '超過帳戶維度可一鍵複製活動數量上限',
        notInheritTip: '不滿足一鍵複製前提條件，是否移除後繼續送出剩餘活動',
        notInheritTip2: '該廣告活動不滿足一鍵複製前提條件',
        matchDegree: '匹配度',
        excellent: '優',
        fine: '良',
        noMatch: '未匹配到一鍵複製後素材',
        matching: '正在匹配中',
        inheritEnd: '已結束複製',
        dateHourTip: '小時維度數據可查詢時間為過去兩週',
        ocpcphase: '廣告已進入第二階段自動優化受眾',
        ocpcNewPhase: '目前活動已進入智能出價',
        dateRemind: 'Pixel轉換數據最近更新時間為:{year}-{month}-{data} {hour}:{minute} {meridian} <span class="date-remind-color">({timezone})</span>',
        downloadTypeDay: '天級報表',
        downloadTypeSummary: '匯總報表',
        accountFilter: '賬戶篩選',
        customerSource: '客戶來源',
        vcpm: 'vCPM',
        country: '國家篩選',
        readChart: 'READ 分析',
        readChartTitle: 'READ 报表',
        total: '總計',
        account: '賬戶',
        allAccounts: '所有賬戶',
        colManagement: '管理',
        colCampaigns: '廣告活動',
        colAds: '廣告',
        colCost: '成本',
        colTrafficAcquisition: '流量獲取',
        colAudienceMetrics: '受眾指標',
        mcv: 'MCV',
        cv: '轉化',
        cvr: '轉化率',
        mcvr: 'MCVR',
        mcpa: '次級轉化成本',
        adSize: '尺寸',
        colTargeting: 'Targeting',
        dimensionFilter: '多維度篩選',
        conversiontype: '轉換類型',
        newConversiontype: 'Discovery Conversion (MCV)',
        mediagoCv: 'MediaGo Conversion (Lead&purchase)',
        currencyType: '多貨幣切換',
        colPerformance: '數據表現',
        payPal: 'PayPal',
        StripeVisa: 'Stripe-Visa',
        StripeMastercard: 'Stripe-Mastercard',
        productName: '產品名稱',
        otherPage: '待解析域名',
        blocked: '(已在帳戶維度封鎖)',
        adListColSize: 'ad設置列 尺寸 選項',
        auditPublisher: '審核詳情媒體分類',
        domainSspColumn: '域名頁ssp屬性',
        dashboardDomainBlock: '域名屏蔽',
        uniformlyFlag: '投放速度',
        campaign: '廣告計劃',
        campaignName: '廣告計劃名稱',
        status: '投放狀態',
        adStatus: 'Ad投放狀態',
        campaignStatus: '投放開關',
        spend: '花費',
        impression: '可視曝光數',
        click: '點擊',
        clickRate: '點擊率',
        startTime: '開始時間',
        endTime: '結束時間',
        searchPlaceholder: '輸入廣告計劃名稱/賬户名稱',
        filterBtn: '篩選',
        refreshBtn: '刷新',
        viewSelectedBtn: '查看所選',
        editSelectedBtn: '編輯所選',
        review: '審核詳情',
        discarded: '這條廣告已被歸檔',
        reviewResult: '審核結果',
        sppNotWork: '廣告活動狀態異常，無法投遞。請聯絡服務窗口了解詳情',
        reviewAssetWaiting: '廣告素材正在等待審核，我們將在3個工作日內完成審核。',
        reviewAssetNotPass: '廣告因違反政策無法投遞，<br/>點擊右側&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按鈕了解詳情',
        reviewAssetReason: 'The creative isn\'t able to run due to policy restrictions',
        reviewAssetReason1: 'The creative can be run, but can not be shown in all situations due to policy restrictions',
        reviewAssetNotPass1: '廣告可投遞，但由於政策限制以致部分網站無法投遞',
        discardedAsset: 'This asset has been archived',
        newReviewNotPass1: '廣告活動中部分素材可以投遞，但由於政策限制以致部分網站無法投遞，<br/>點擊右側&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按鈕了解詳情',
        newReviewAssetWaiting: '廣告可投遞，但由於政策限制以致部分網站無法投遞',
        newReviewNotPass: '廣告活動中部分素材因違反政策無法投遞，<br/>點擊右側&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按鈕了解詳情',
        reviewHasRemain: '{wait_audit_asset_num}/{asset_num} 廣告素材： 等待審核狀態 <br/>',
        reviewWaiting1: '廣告活動中部分素材正在等待審核，不影響廣告活動已通過審核的素材正常投遞。<br/>正在等待審核的素材，我們將在3個工作日內完成審核。',
        reviewWaiting: '廣告正在等待審核，<br/>我們將在3個工作日內完成審核。請耐心等待…',
        reviewNotPass: '該Campaign中有素材未通過審核，<br/>點擊右側&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按鈕瞭解詳情',
        reviewNotPass1: '該Asset中有素材未通過審核，<br/>點擊右側&nbsp;<i class="msn-fa msn-fa-tool-small"></i>&nbsp;按鈕瞭解詳情',
        // reviewNotPass: '審核未通過，<br/>請聯繫你的賬户管理員',
        auditDetails: '詳情',
        campaignFilter: '廣告計劃篩選',
        adSetFilter: '廣告組篩選',
        adFilter: '廣告篩選',
        datetimeRange: '時間範圍',
        and: '至',
        selectDate: '選擇時間',
        on: '開啓',
        off: '關閉',
        CPC: 'CPC',
        totalBudget: '總預算',
        roas: 'ROAS',
        dailyBudget: '天預算',
        campaignDetails: '廣告計劃詳情',
        campaignCopy: '複製廣告計劃',
        adSetsDetails: '廣告組詳情',
        adsDetails: '廣告詳情',
        campaignEdit: '廣告計劃編輯',
        adCopy: '復製廣告',
        adEdit: '廣告編輯',
        creative: '素材',
        utcStandard: 'UTC標準時間',
        objective: '目標',
        accountsName: '賬户名',
        adCreator: '廣告創建人',
        // audit
        assetTitle: '標題',
        asset: '圖片',
        assetName: '素材名稱',
        adInformation: '廣告信息',
        accountName: '廣告主名稱',
        adName: '廣告名稱',
        submitTime: '創建時間',
        start: '投放時間',
        userId: '用户ID',
        reviewItems: '審核內容',
        pass: '通過',
        fail: '部分廣告被拒絕',
        invalidReason: '拒審原因',
        comments: '審核員建議',
        reviewer: '審核人',
        reviewTime: '審核時間',
        colCfgTitle: '設置列',
        colDimension: '維度',
        colAdPerformance: '廣告數據表現',
        colContentPerformance: '內容數據表現',
        campaignId: '廣告計劃ID',
        accountId: '賬户ID',
        colStatus: '投放狀態',
        colStart: '開始時間',
        colEnd: '結束時間',
        colSubmissionTime: '提交時間',
        colSpend: '花費',
        // colBalance: '可用額度',
        colBalance: '活動剩餘預算',
        colTotalBudget: '總預算',
        colDailyBudget: '天預算',
        colDetail: '詳情',
        colDelivery: 'Delivery',
        colOther: '其他',
        colCpc: 'CPC',
        colAvgCpc: '平均CPC',
        colVCpm: 'vCPM',
        colImp: '可視曝光數',
        colClick: '點擊',
        colCtr: '點擊率',
        colConversion: '轉化',
        newColConversion: '轉化',
        cvViewContent: '查看內容',
        cvAppInstall: '安裝',
        cvCompleteRegistration: '完成註冊',
        cvAddToCar: '加入購物車',
        cvAddPaymentInfo: '輸入付款資訊',
        cvSearch: '搜索',
        cvStartCheckout: '開始結賬',
        cvPurchase: '購買',
        cvAddToWishlist: '收藏',
        cvLead: '潛在顧客',
        cvOther: '其它',
        colAccount: '賬户信息',
        conversions: '轉化',
        conversion: '轉化',
        conversionRate: '轉化率',
        colConversionRate: '轉化率',
        costPerConversion: 'CPA',
        colConversionPrice: 'CPA',

        newConversion: '轉化',
        newConversions: '轉化',
        newConversionRate: '轉化率',
        newColConversionRate: '轉化率',
        newColAgency: '代理商',
        newColAgencyInput: '搜索代理商名稱/代理商ID',
        newColAgencyName: '代理商名稱',
        newColAgencyId: '代理商ID',
        newCostPerConversion: 'CPA',
        newColConversionPrice: 'CPA',
        campaignMultiEdits: {
            selectIdsNum: '正在編輯1條廣告計劃 | 正在編輯{num}條廣告計劃',
            selectAssetIdsNum: '正在編輯1條創意 | 正在編輯{num}條創意',
            dialogTitle: '批量編輯',
            status: '狀態',
            bid: '出價',
            perCampaign: '每個廣告系列',
            dailySpendCap: '日預算',
            lifetimeSpendCap: '總預算',
            bulkEditSuccess: '批量編輯廣告計劃成功',
            assetBulkEditSuccess: '批量編輯creative成功',
            bulkArchivedSuccess: '批量歸檔廣告計劃成功',
            assetBulkArchivedSuccess: '批量歸檔creative成功'
        },
        accountList: {
            searchPlaceholder: '搜索賬戶名稱/賬戶ID'
        },
        adList: {
            head: '廣告列表',
            adNum: '已創建：$num條',
            id: '廣告Id',
            status: '投放狀態',
            fileName: '文件名稱',
            name: '素材名稱',
            image: '創意',
            size: '尺寸',
            title: '標題',
            brandName: '品牌名稱',
            spend: '花費',
            impression: '可視曝光數',
            ctr: '點擊率',
            url: '網頁鏈接',
            searchPlaceholder: '輸入標題/廣告Id/URL',
            click: '點擊數',
            webUrl: '網頁鏈接',
            cpc: 'CPC',
            campaignId: '廣告計劃ID',
            campaignName: '廣告計劃名稱',
            assetId: '廣告系列ID',
            tableFooterMsg: '{n}条广告正在创建中',
            searchAssetId: '輸入標題/廣告系列Id/URL',
            failedMsg: '圖片上傳失敗'
        },
        domain: {
            domainName: 'Site',
            spend: '花費',
            impression: '可視曝光數',
            click: '點擊數',
            ctr: '點擊率',
            vcpm: 'vCPM',
            acpc: '平均CPC',
            suggestedCpc: '建議CPC',
            cpc: 'CPC',
            searchPlaceholder: '搜索域名',
            blockBtn: '屏蔽',
            blockDisableBtn: '屏蔽（無操作權限）',
            resumeBtn: '解除屏蔽',
            resumeDisableBtn: '解除屏蔽（無操作權限）',
            resumeDisableAccountBtn: '請回到帳戶層級進行解除',
            ssp: 'SSP',
            domainNameRemark: '域名維度的數據更新會有2個小時的延遲'
        },
        domainTitle: 'Sites',
        // data report column setting
        colBreakDownBy: '細分維度',
        colCampaignAds: '廣告信息',
        suffixBreakDownBy: '你最多可以選擇2個維度',
        colData: '數據指標',
        colEarning: '收入',
        yesterday: '昨天',
        today: '今天',
        last7days: '過去7天',
        last30days: '過去30天',
        archivedMsg: '歸檔後，您將不能再編輯這條廣告，但廣告數據會保留',
        archivedMsg2: '歸檔後，您將不能再編輯這些廣告，但廣告數據會保留',
        archivedAssetMsg: '歸檔後，您將不能再編輯這些創意，但創意數據會保留',
        filterList: '廣告計劃列表設置',
        adFilterList: '廣告列表設置',
        hideArchivedCmps: '隱藏歸檔廣告',
        hideArchivedAds: '隱藏歸檔廣告',
        onOffBoth: '展示所有廣告',
        adOnOffBoth: '展示所有廣告',
        showAllCampaigns: '展示所有廣告（包括歸檔廣告）',
        onlyArchivedCmps: '只展示歸檔廣告',
        showAllAds: '展示所有廣告（包括歸檔廣告）',
        onlyArchivedAds: '只展示歸檔廣告',
        selectedCampaigns: '已選擇{num}條',
        adInfo: '{num}條廣告計劃',
        domainCampaignTip: '{num}條廣告計劃',
        domainAccountTipAll: '所有賬户',
        domainAccountTip: '{num}個賬户',
        mediaBlackWhiteListTitle: '媒體帳戶/Site白名单',
        mediaType: '媒體類型',
        selectedZipCode: '郵編: {num} 個',
        campaignSetOcpc: 'OCPC',
        campaignSetEcpc: 'ECPC',
        bidByDomain: 'bid by domainCPC',
        suggested: 'Site建議CPC',
        readChartObj: {
            avgReadTime: '平均閱讀時間',
            avgReadRate: '平均閱讀率',
            totalReadTime: '總閱讀時間',
            readPart: 'READ分布',
            readAnalysis: 'READ分析',
            pv: 'PV數',
            readRate: '閱讀率',
            readUserPart: 'READ 用戶區',
            tiaoReadPart: '跳讀區',
            shuReadPart: '熟讀區',
            yueReadPart: '閱讀區',
            popInAvgReadRate: 'popIn流入時平均閱讀率',
            inAvgReadRate: '一般流入時平均閱讀率',
            contentAnalysis: '內容分析',
            clickNumber: '點擊次數',
            imp: 'Imp',
            ctr: 'CTR',
            outMediaAnalysis: '外部媒體內容消費模式分析',
            normalReadContent: '平時閱讀的內容',
            normalReadTimePeriod: '平時閱讀的時間段',
            timePeriod: '時間段',
            category: '類別'
        }
    },
    create: {
        trackingSuffixTip: '網頁連結增加該tracking後綴不影響pixel的正常使用。',
        newCountryLang: {
            Japanese: '日語',
            English: '英語',
            Chinese: '中文',
            Thai: '泰語',
            Malay: '馬來語',
            Indonesian: '印尼語',
            Korean: '韓語',
            allLanguageSplit: '所有語言選擇明確語言',
            LanguageErrTip: '語言不能為空'
        },
        maxCvTipFirst: '1.初期測試建議活動日預算為5~10倍目標CPA',
        maxCvTipLast: '2.防止 MaxCV 模式下 CPA 過高，每天建議最多上調 1 次日預算，且上調幅度在 30% 以內',
        dataInheritance: 'Data Inheritance',
        dataInheritanceInternal: 'Data Inheritance（internal）',
        imgUploadSizeUpperLimit: '素材增加上限',
        smartBidmaxcv: 'SmartBid Max CV',
        smartBidmaxcvTip: '如果您希望在預算範圍內盡可能獲得更多轉換，建議使用此出價策略。',
        submitTitle: '在圖片，標題等廣告創意裡，沒有擅自侵害/盜用他人姓名，照片，談話或肖像，商標，著作物等內容',
        requiredTitle: '(必填)',
        picSize: '圖片尺寸',
        displaySize: 'Banner Ads Upload',
        anySize: '任意尺寸',
        recommendedSize: 'Display廣告建議尺寸',
        plsSelectOne: '請選擇【圖片尺寸】',
        plsSize: '請上傳 [{size}] 尺寸的圖片',
        campaignCategoryInfo: 'Define a category for your campaign content.The effectiveness of campaign optimization is highly related to the category, please select carefully.',
        campaignCategory: 'Campaign Category',
        createCampaignSetps: 'Create A Campaign',
        addAds: 'Add Creatives',
        conversionGoal: 'Conversion Goal',
        basicSettings: 'General Settings',
        campaignTargeting: 'Campaign Targeting',
        marketingObjective: 'Marketing Objective',
        scheduleAndBudget: 'Schedule and Budget',
        smartBid: 'Smart Bid',
        smartBidLearning: 'Smart Bid (Learning)',
        smartBidLearningTip: 'MediaGo is learning how to best deliver your campaign. Performance may improve further. Please avoid editing your ad set during the learning phase. This phase uses Fixed Bid.',
        smartBidLearningCompleted: 'Smart Bid (Learning Completed)',
        smartBidLearningCompletedTip: 'The learning phase has complete for this campaign. Smart Bid is already in use.',
        uploadStatus: 'ステータス',
        audienceTip: '为您的广告活动选择目标受众群体，添加更多受众类型将扩大广告活动的用户影响范围。',
        singleUpload: '手動入稿',
        bulkUpload: '一括入稿',
        uploadFile: 'インポート',
        uploadCsvFile: 'CSVファイルをインポート',
        downloadTemplateCsv: 'テンプレートをダウンロード',
        csvUploadTip: '下記リンクよりバルクシートのテンプレートをダウンロードしてください。<br>バルクシートをインポートする際に、テンプレート内の３行目の記入例を削除してください。<br>《入稿項目》<br>入稿URL、UTMトラッキングコード（必須ではない）、カテゴリー、タイトル、広告主名、画像。<br>バルクシートのフォーマットは以下です。<br>・ファイル形式：.csv<br>・⽂字コード︓UTF-8<br>・コンマ区切り',
        dragUpload: 'ドラッグ＆ドロップ',
        plsUploadCsv: 'CSVファイルをアップロードしてください',
        uploadRecord: '	インポートログ',
        csvRecordTip: 'キャンペーンあたり登録可能なクリエティブ(asset)数：最大100',
        uploadTime: 'インポート時間',
        uploadRemark: '備考',
        file: 'ファイル名',
        magnitudeEvaluation: '評估量表',
        thirdPartyTracking: '第三方追蹤',
        tagType: '標籤類型 ',
        eventType: '事件類型',
        url_code: '添加您的鏈接/代碼',
        click: '點擊',
        viewImperssion: '可視展示',
        trackingTips: '您可以在此處添加第三方跟踪以捕獲您的廣告計劃數據並將其傳送到外部平台以實現您的營銷目標。第三方跟踪支持的宏與 UTM 支持的宏相同。了解更多 請注意：使用 JavaScript tag 時，由於部分流量不支持此類跟踪，因此跟踪數據與實際數據可能存在差異。',
        jp: '日本',
        au: '澳大利亞',
        gb: '英國',
        sg: '新加坡',
        my: '馬來西亞',
        th: '泰國',
        hk: '香港',
        tw: '臺灣',
        kr: '韓國',
        id: '印度尼西亞',
        preview: '圖片預覽',
        collapse: '收起',
        expand: '展開',
        de: '德國',
        fr: '法國',
        it: '意大利',
        es: '西班牙',
        at: '奧地利',
        br: 'Brazil',
        mx: 'Mexico',
        nl: 'Netherlands',
        pl: 'Poland',
        se: 'Sweden',
        be: 'Belgium',
        pt: 'Portugal',
        ch: 'Switzerland',
        cz: 'Czechia',
        no: 'Norway',
        dk: 'Denmark',
        hu: 'Hungary',
        deLang: '德語',
        itLang: '意大利語',
        frLang: '法語',
        // campaign
        createCampaign: '排期和預算',
        accountName: '廣告賬户名稱',
        campaign: '廣告計劃',
        objective: '目標',
        promotionObjective: '廣告目標',
        completeConversion: '完成轉換',
        purchaseOrder: '購買',
        willingnessToInteract: '互動',
        fillForm: '填寫表單',
        signUp: '會員註冊',
        downLoadData: '下載資料',
        sendMessage: '發送訊息',
        browseWebpage: '瀏覽頁面',
        reputation: '品牌認知',
        enhanceBrand: '提升品牌知名度',
        objectiveTraffic: '網站流量',
        objectiveAwareness: '品牌認知',
        objectiveConversions: '轉化',
        objectiveLead: '潛在客户',
        campaignName: '廣告計劃名稱',
        campaignStatus: '投放開關',
        startAndEnd: '開始和結束',
        startTime: '開始時間',
        endTime: '結束時間',
        setTime: '設置時間',
        immediately: '立即開始',
        indefinitely: '長期投放',
        dayparting: '分時段',
        selectDay: '選擇您想投放的時間和日期',
        dailySpendCap: '單日預算',
        dailyCapTip: '實際天級花費將低於或高於（最多兩倍）單日預算。',
        lifetimeSpendCap: '總預算',
        lifetimeSpendCapTip: '您可以指定每月或活動總預算',
        lifetimeBudget: '總預算',
        monthlyBudget: '月預算',
        pacing: '投放速度',
        // ad set
        search: '搜索...',
        English: '英語',
        Spanish: '西班牙語',
        Japanese: '日語',
        All: '所有',
        lang: '語言',
        countryUsa: '美國',
        countryCanada: '加拿大',
        adSetTitle: '目標設置',
        adSets: '廣告組',
        adSetsName: '廣告組名稱',
        adSetsStatus: '廣告組狀態',
        adFormat: '廣告格式',
        placement: '廣告位',
        size: '尺寸',
        homepage: '首頁',
        audience: '受眾',
        audienceAsiaTip: '注意：所選受眾在亞洲地區流量受限',
        audienceAmericaTip: '注意：所選受眾在歐美地區流量受限',
        industry: '類型',
        industryPh: '請選擇類型',
        industryInfo: `請選擇最多兩組短語描述上述落地頁中的產品或服務類型。精準的描述有利於算法自動為廣告計劃適配合適的流量。
        * 請勿隨意更改類型。`,
        location: '地區',
        state: '州',
        us_state: '州',
        ca_state: '地區',
        au_state: '州',
        gb_state: '地區',
        jp_state: '地區',
        tw_state: '地區',
        id_state: '地區',
        kr_state: '地區',
        hk_state: '地區',
        sg_state: '地區',
        my_state: '地區',
        th_state: '地區',
        // 必须按照缩写_placeholder的格式填写 有多个地方用到
        us_placeholder: '州/郵編',
        ca_placeholder: '地區',
        au_placeholder: '州',
        gb_placeholder: '地區',
        jp_placeholder: '地區',
        tw_placeholder: '地區',
        id_placeholder: '地區',
        kr_placeholder: '地區',
        hk_placeholder: '地區',
        sg_placeholder: '地區',
        my_placeholder: '地區',
        th_placeholder: '地區',
        targetBid: '目標出價',
        contentCategory: '受眾類型',
        device: '設備',
        os: '系統',
        osv: 'win10定向投放',
        userPackage: '受眾包',
        domainwhitelist: '域名白名單',
        moreBtn: '多素材功能',
        impURL: '第三方數據追踪',
        osvTip: '勾選後，你的廣告將會被定向投放至Windows 10操作系統。',
        amountSmall: '請注意：你所定向的平台-系統-瀏覽器組合流量較少，可能會影響廣告投放效果，建議嘗試其他平台-系統-瀏覽器組合進行投放。',
        noAmount: '請注意：你所選擇的定向系統-瀏覽器組合沒有流量，請重新選擇',
        platform: '平台',
        browser: '瀏覽器',
        gender: '性别',
        carrier: '運營商',
        include: '包含',
        exclude: '排除',
        only: '僅包含',
        zipcode: '郵編',
        // 需要有一些失敗提醒
        locationTip: '請將信息填寫完整',
        locationTip1: ' 不能為空',
        locationCheck: '僅限該地區IP',
        locationCheckTip: '勾選後，僅限IP地址在所選地區的用戶。',
        // ad
        allSelect: '全選',
        allSelect1: '全選',
        createAds: '素材',
        adName: '廣告名稱',
        adAssetName: '素材名稱',
        adStatus: '廣告狀態',
        brandName: '品牌名稱',
        headline: '標題',
        asset: '圖片&圖片名稱',
        webUrl: '網頁鏈接',
        upload: '上傳',
        reUpload: '重新上傳',
        utmSuffix: '廣告追蹤',
        urlPreview: '廣告組合預覽',
        // imageDescription: '為提升點擊率，推薦上傳比例為16:9、且圖片素材居中（與邊框保持適當間距）的圖片。圖片最大為2MB，分辨率需大於600x400像素。',
        imageDescription: '圖片最大為2MB，最小分辨率：600x400像素。',
        // CAMPAIGN_ID: '廣告計劃id',
        // AD_ID: '廣告id',
        // AD_NAME: '廣告名字',
        // PAGE_TYPE: '頁面類型，DHP，HP等',
        // AD_STYLE: '廣告樣式，infopane，river等',
        // REQ_ID: '請求id，rid',
        // BID_TIME_STAMP: '廣告填充時間戳（精度，以go支持的精度為主）',
        // TRACKING_ID: '跟蹤id',
        // CPC: '此次廣告的CPC'
        more: '查看詳情',
        utmInfos: '輸入UTM代碼進行廣告活動追蹤。',
        titleInfo: '你可以在標題中設置動態宏',
        titleTemplate1: '該值將被替換為用戶正在瀏覽的地區（包括州/省）',
        titleTemplate2: '這個宏會被替換成月份，比如：January',
        utmInfoHeader: '推薦追蹤代碼',
        titleInfoHeader: '例如:',
        assetPreview: '素材預覽',
        adList: '廣告列表',
        adHead: {
            id: '廣告Id',
            name: '素材名稱',
            image: '圖片',
            title: '標題',
            brandName: '品牌名稱',
            url: '網頁鏈接',
            searchPlaceholder: '標題/素材名稱'
        },
        assetAditionInfo: '更多流量接入中...',
        closedText: '敬請期待...',
        delAdMsg: '確認後，舊的Ad將被歸檔，但數據會被保留。確認嗎？',
        mdyAdMsg: '確認後，舊的Ad將被歸檔，但數據會被保留。確認嗎？',
        disableSizeMsg: '我們建議您保留全部的素材尺寸，我們的算法會從所有的尺寸中挑選出表現最好的進行優化。',
        importZipCode: '導入郵編',
        importDomainList: '導入domain白名單',
        selectCsv: '選擇一個CSV文件',
        downloadTemplate: '下載模版',
        previewTip: '內容推薦有多種形式和大小。最終的展示位置和廣告素材排列（圖片尺寸，標題和品牌名稱）取決於它們被展示的媒體平台。',
        domainTip: '你可以通過CSV文件上傳所需要投放的domain白名單，但需要注意的是這些白名單隻有與平台現存domain匹配才能生效，且匹配成功的白名單隻對mobile定向流量生效',
        fixedCpc: '固定CPC',
        notCut: '廣告圖片不裁剪',
        notCutTip: '如未勾選，系統將依網站需求比例自動裁剪圖片，一經勾選後則無法取消',
        OCPC: 'OCPC',
        ECPC: 'ECPC',
        ocpcTip: '出價將依據轉換目標進行動態調整。 選擇此功能後，網站個別競價功能將無效。[支援地區：日本]',
        cpaMaxChangeTip: '輸入CPA的最大調整幅度。為更好的優化CPA, 系統在學習期中也有可能超過上限。',
        ecpcTip: '系統會在CPC*出價調整幅度範圍內動態調整出價，以獲得更好的轉換效益。選擇此功能後，網站個別競價的CPC 將隨此 CPC 的變動調整而改變。[支援地區：美國,加拿大,英國,澳洲]',
        cpaTypeTip: '為幫助系統獲得更好的自動優化學習，CPA類型選擇後，建議不要修改',
        fisrtStepCpc: '第一階段CPC',
        secondStepTargetCpa: '第二階段目標CPA',
        secondStepTargetMcpa: '第二階段目標MCPA',
        cpaMaxChange: 'CPA調整幅度上限',
        cpcChange: '出價調節幅度',
        targetCpa: '目標CPA',
        cpaType: 'CPA類型',
        targetCpaRequired: '請填入目標CPA',
        targetCpanumbered: '目標CPA須為數字',
        bidType: '出價方式',
        fixedBid: '固定CPC出價',
        smartBidText: 'OCPC',
        smartBidTargetCPA: 'SmartBid Target CPA',
        smartBidTargetCPATip: 'Target CPA設定較低，將無法進入智慧出價。',
        smartBidNext: 'Smart Bid',
        smartBid: 'oCPC智慧出價（目前僅適用於日本&臺灣ip）',
        phaseCpc: '第一階段固定CPC',
        smartBidTips: '智慧出價是一組以轉換為基準的出價策略，會運用進階機器學習技術，協助您在每次競價時找到最適當的出價，還能將各式各樣的競價期間信號 (包括裝置、位置、時段、再行銷名單、語言，以及作業系統) 納入考量。',
        smartBidNextTips: '如果您希望系統自動最大化廣告活動效果，建議使用此出價策略。',
        smartTargetCpaTips: '目標CPA：埋設popIn CV位置的預期目標CPA。\n系統將根據您填入的目標CPA優化投放成效。\n請填入您的預期目標CPA，以免影響系統的優化效果。',
        upToAdsLimitMsg: '該廣告計劃下 ads 數量最多{limit}條',
        previewImgTip: '圖片比例 ',
        previewImgTitle: '您的標題在這裡',
        previewImgName: '您的品牌名稱在這裡',
        theThirdPartyPixelPrompt: '您可以在此處添加第三方跟蹤鏈接以捕獲您的活動數據並將其傳送到外部平臺以促進您的營銷目標。 第三方跟蹤支持的宏與 UTM 的相同，',
        PixelPromptMore: '點擊了解更多。',
        postback: 'Postback',
        postbackNull: 'None',
        mPostbackNull: 'None',
        discovery: 'Discovery',
        postback91App: '91App',
        mediago: 'MediaGo',
        postbackTip: '此postback設定將會套用至對應廣告活動中的所有廣告文案。選擇「None」，將會影響歸因準確性',
        assetInfo: '支持上傳多張圖片</br>建議長寬比：16:9</br>圖片檔案格式：JPG/JPEG/PNG/GIF</br>圖片檔案大小上限：5 MB</br>建議產品/LOGO等重要資訊內縮20%',
        assetSingleInfo: '建議長寬比：16:9</br>圖片檔案格式：JPG/JPEG/PNG/GIF</br>圖片檔案大小上限：5 MB</br>建議產品/LOGO等重要資訊內縮20%',
        assetInfoTip: '圖片大小：不超過5MB',
        gifSizeTip: '；GIF大小：不超過10MB',
        createCampaignBtnText: '送出廣告活動',
        campaignNoAssetsTips: '該廣告活動尚未上傳素材',
        addAssetsBtnText: '增加素材'
    },
    audit: {
        review: '審核',
        auditStatus: '審核狀態',
        detail: '詳情',
        reAudit: '重新審核',
        reviewAds: '審核廣告',
        status: '狀態',
        inReview: '等待審核',
        preliminaryReview: '等待初審',
        secondReview: '等待複審',
        rejected: '未通過',
        limited: '投遞受限',
        rejectedSelect: '部分廣告被拒絕',
        manuallyApproved: '人審通過',
        bdApproved: '百度審核員審核通過',
        msApproved: '微軟審核員審核通過',
        bdRejected: '百度審核員審核不通過',
        msRejected: '微軟審核員審核不通過',
        adId: '廣告ID',
        campaignName: '廣告計劃名稱',
        accountName: '賬戶名稱',
        submittedTime: '提交時間',
        startTime: '投放時間',
        adName: '廣告名稱',
        operate: '操作',
        startReviewing: '開始審核',
        searchPlaceholder: '搜索廣告主或廣告名稱',
        adInfo: '廣告信息',
        userId: '用户ID',
        reviewItems: '審核內容',
        pass: '通過',
        fail: '不通過',
        reviewResult: '審核結果',
        conclusion: '結論',
        approved: '審核通過',
        abnormal: '狀態異常',
        description: '说明',
        comments: '建議',
        skip: '下一條/跳過',
        submitAndNext: '提交併繼續',
        reviewer: '審核人',
        reviewTime: '審核時間',
        reviewed: '已審核',
        reviewedLines: '已審核: {num}條',
        team: '審核團隊',
        result: '審核結果',
        reason: '拒審原因'
    },
    auditEn: {
        isIncludeTestAccount: 'Include test account',
        confirmation: 'confirmation',
        searchSimilar: '批量審核',
        assetType: '素材类型',
        nativeAsset: 'Native',
        displayAsset: 'Display',
        mediaType: '媒體',
        location: '地區',
        language: '語言',
        isGif: 'GIF image',
        languageTip: '英語素材不能跑西班牙語',
        audience: '受眾',
        industry: '類型',
        startTime: '開始時間',
        brandName: '品牌名稱',
        headline: '標題',
        asset: '圖片素材',
        landingPage: '網頁鏈接',
        webUrl: '網頁鏈接',
        mApproved: 'In Machine Review',
        preliminaryReview: '初審',
        secondReview: '複審',
        rejected: '拒絕',
        manuallyApproved: '人工審核通過',
        bdApproved: '百度審核通過',
        msApproved: '媒體審核通過',
        bdRejected: '百度拒審',
        msRejected: '媒體拒審',
        operate: '操作',
        startReviewing: '開始審核',
        searchPlaceholder: '廣告計劃/賬戶/審核員',
        adId: '廣告ID',
        assetId: '廣告系列ID',
        assetName: '廣告系列名稱',
        campaignName: '廣告計劃名稱',
        accountName: '賬戶名稱',
        submittedTime: '提交時間',
        assetSubmissionTime: '素材提交時間',
        campaignSubmissionTime: '廣告活動提交時間',
        reviewItems: '審核對象',
        pass: '通過',
        fail: '未通過',
        reviewResult: '審核結果',
        conclusion: '結論',
        approved: '通過',
        description: '描述',
        comments: '建議',
        skip: '跳過',
        submitAndNext: '提交並跳轉至下一條',
        reviewer: '審核員',
        reviewTime: '審核時間',
        reviewed: '已審核',
        reviewedLines: '已審核 {num}項',
        team: '審核團隊',
        result: '審核結果',
        reason: '審核原因',
        status: '狀態',
        detail: '詳情',
        review: '審核',
        reAudit: '重新審核',
        adInfo: '廣告信息',
        adName: '廣告名稱',
        operateRecord: '審核記錄',
        sellerReviewRecords: 'Seller維度審核記錄',
        baiduBrainScanRecords: 'Baidu Brain Scan Records',
        refused: 'Refused',
        brainApproved: 'Approved',
        suspected: 'Suspected',
        others: 'Others',
        modify: 'Modify',
        baiduBrainScan: 'Baidu Brain Scan',
        baiduBrainScanResult: 'Baidu Brain Scan Result',
        baiduBrainRejectReason: 'Baidu Brain Reject Reason',
        baiduBrainReviewTag: 'Baidu Brain Review Tag',
        noData: 'No Data',
        lowLevelPornographic: 'Low Level Pornographic;',
        highLevelPornographic: 'High Level Pornographic;',
        lowLevelPolitics: 'Low Level Politics;',
        highLevelPolitics: 'High Level Politics;',
        lowLevelViolenceAndTerrorism: 'Low Level Violence And Terrorism;',
        highLevelViolenceAndTerrorism: 'High Level Violence And Terrorism;',
        lowLevelProhibited: 'Low Level Prohibited;',
        highLevelProhibited: 'High Level Prohibited;',
        lowLevelDisgustingPicture: 'Low Level Disgusting Picture;',
        highLevelDisgustingPicture: 'High Level Disgusting Picture;',
        submit: '提交',
        cancel: '取消',
        confirm: '確認',
        skipInfo: '你將丟失內容，是否確定退出？',
        leaveInfo: '你將丟失內容，是否確定退出？',
        reviewNotComplete: '審核未完成',
        requestError: '網絡錯誤，請重試',
        discarded: '存檔',
        pass1next: '審核通過跳轉至下一條',
        passAction: '通過',
        geoEdge: '地理邊緣',
        displayAllImg: '一次性展示所有審核圖片',
        setSensitive: '敏感分類',
        baiduBrain: 'Baidu Brain'
    },
    creditLine: {
        currencyConverterTip: '匯率換算規則：匯率換算以上月日均匯率為準。 匯率基於The Federal Reserve System(美聯儲系統)<a>http://www.federalreserve.gov/releases/h10/hist/default.htm</a>',
        tradingCurrencies: '交易幣別',
        accountName: '賬户名稱',
        accountId: '賬户ID',
        status: '賬户狀態',
        companyName: '公司名稱',
        availableBalance: '總可用額度',
        availableBalanceRemark: '總可用額度=帳户餘額+信用額度+Coupon總消耗+有效Coupon總餘額（交易幣別）',
        balance: '帳户餘額',
        balanceRemark: '賬户餘額=累計充值-累計花費（cpc）（交易幣別）',
        totalRecharge: '累計充值',
        totalRechargeRemark: '歷史充值金額累計',
        creditLinetotalRechargeRemark: '歷史充值金額累計（交易幣別）',
        totalSpend: '累計花費',
        untaxedCopy: '所有花費是累計未稅金額，可能與Invoice的累計含稅金額不同',
        totalSpendRemark: '歷史花費金額累計（交易幣別）',
        creditBalance: '信用餘額',
        creditBalanceRemark: '當賬户餘額+Coupon總消耗>=0，信用餘額=信用額度;當賬户餘額+Coupon總消耗<0, 信用餘額=信用額度 + 帳户餘額+Coupon總消耗（交易幣別）',
        creditLine: '信用額度',
        creditLineRemark: '我們給賬户設置的可額外使用的總信用額度（交易幣別）',
        balanceNote: '當前賬户總可用額度小於$200，請注意',
        balanceNote1: '為保證廣告不受影響，請儘快充值',
        filter: '篩選',
        charge: '充值',
        edit: '編輯',
        editTitle: '設置信用額度',
        chargeTitle: '充值',
        currencyTitle: '結算幣種',
        tradingCurrencyLable: ' 交易幣別',
        contractualTitle: '合同主體',
        agent: '代理商',
        userName: '操作人',
        note: '備註',
        operationTime: '操作時間',
        amount: '充值金額',
        chargeTime: '充值時間',
        editRecordTitle: '信用額度修改記錄',
        chargeRecordTitle: '充值記錄',
        opt: '操作',
        couponBalance: '有效Coupon總餘額',
        couponBalanceRemark: '有效Coupon餘額的累加（交易幣別）',
        couponSpend: 'Coupon總消耗',
        couponSpendRemark: 'Coupon已消耗累加（交易幣別）',
        couponTitle: 'Coupon',
        couponAmount: 'Coupon金額',
        effectiveTime: '生效時間',
        expireTime: '過期時間',
        offline: '下線',
        offlineTip: '確認後該Coupon將永久下線，無法恢復，確認嗎？',
        profitRate: '利潤率（預期）',
        profitRateRemark: '手動設置的期望該賬户達到的利潤率。<br/>該利潤率會影響實際出價。',
        profitRateTitle: '設置利潤率',
        profitRateRecordTitle: '利潤率修改記錄',
        curProfitRate: '當前利潤率',
        transactionCurrency: '交易幣別',
        settletmentCurrency: '結算幣別',
        couponCost: '本期Coupon消耗',
        adjustmentAmount: '變動金額',
        receivablesWithTax: '應收含稅花費',
        adjustmentAmountRecord: '金額變動紀錄',
        receivablesWithoutTax: '應收未稅花費',
        currentCoupons: '本期Coupon消耗',
        selectMonth: '選擇時間區間',
        amountSetting: '變動金額設置',
        setAmount: '設置變動金額',
        searchText: '搜尋帳戶/公司/代理商名稱',
        searchMonthlyText: 'Search Account/Agency Name',
        amountRecords: '變動金額修改紀錄',
        spendPlusTaxRemark: '所選時間內，帳戶的累計未稅花費',
        couponCostRemark: '所選時間內，帳戶的Coupon總消耗',
        adjustmentAmountRemark: '所選時間內，帳戶調整後的總金額（未稅）',
        consumptionTaxRemark: '(調整後)當期未稅花費的增值稅',
        monthlyExchangeRateRemark: '結算月平均月匯率',
        receivablesWithTaxRemark: '所選時間內，帳戶的累計含稅花費',
        receivablesWithoutTaxRemark: '所選時間內，帳戶應收帳款（未稅）（交易幣種）',
        currentUntaxedSpend: '本期未稅花費',
        settlementCurrency: '結算幣別',
        rebate: '返點金額',
        monthText: '每月3號後不可對以往款項進行修改',
        adjustedText: '請輸入完整的需要調整的金額',
        changeRecords: '修改紀錄',
        remarkNote: '備註',
        changeDate: '更改時間區間',
        amountEdit: '變動金額編輯',
        release: '發布'
    },
    roiMgr: {
        serviceType: '服務類型',
        category: '行業分類',
        companyName: '公司名稱',
        accountName: '賬户名稱',
        accountId: '賬户ID',
        income: '百度當期收入',
        incomeRemark: '百度當期收入=賬户當期消耗-當期Coupon成本',
        spend: '賬户當期消耗',
        spendRemark: '所選時間內的賬户累計花費',
        cost: '當期流量成本',
        costRemark: '所選時間範圍內，我們付給msn的錢（CPM）',
        profit: '當期利潤',
        profitRemark: '當期利潤=百度當期收入-當期流量成本',
        profitRate: '利潤率（實際）',
        profitRateRemark: '利潤率=當期利潤/百度當期收入',
        impression: '展示數量',
        click: '點擊數',
        filter: '篩選',
        coupon: '當期Coupon成本',
        couponRemark: '所選時間內Coupon總消耗',
        rebateRatio: '返點比例',
        rebateRatioRemark: '在ROI報表頁面，代理商和旗下廣告主均展示返點比例。',
        source: '客户來源',
        sourceCompany: '客户來源公司',
        accountType: '賬户性質',
        rebate: '當期返點成本',
        rebateRemark: '當期返點成本 = 百度當期收入 x 返點比例',
        launchDays: '投放天數',
        averageDailySpend: '日均消耗',
        maximumDailySpend: '最高日消耗'
    },
    // 可視化測試
    visualTest: {
        adminEmailCode: '管理員獲取信箱驗證碼'
    },
    financiaIncome: {
        accountId: '賬户ID',
        companyName: '公司名稱',
        accountName: '賬户名稱',
        timezone: '賬户時區',
        email: '開户人信箱',
        submitTime: '創建時間',
        spend: '賬户當期消耗',
        spendRemark: '所選時間內的賬户累計花費',
        coupon: '當前Coupon發放',
        couponRemark: '所選時間內Coupon總消耗',
        receivablesRemark: '財務應收=賬户當期消耗-當期Coupon成本',
        recharge: '當期充值金額',
        receivables: '財務應收',
        businessManager: '商務經理'
    },
    financiaPay: {
        accountType: '賬户性質',
        accountId: '賬户ID',
        source: '客户來源',
        rebateRatio: '返點比例',
        rebateRatioRemark: '在財務應付頁面，代理商下的廣告主的返點比例為0（不展示在這裏）。',
        companyName: '公司名稱',
        accountName: '賬户名稱',
        timezone: '賬户時區',
        email: '開户人信箱',
        cost: '當期流量成本',
        businessManager: '商務經理',
        costRemark: '所選時間範圍內，我們付給msn的錢（CPM）',
        rebate: '當期返點成本',
        sspName: '投放媒體',
        rebateRemark: '當期返點成本（應付）：代理商下的各個廣告賬户的當期返點成本累加和。<br/>代理商下的廣告賬户產生的消耗，返點金額應支付給代理商公司本身'
    },
    dataReport: {
        account: '賬户',
        device: '設備',
        os: '系統',
        platform: '平台',
        browser: '瀏覽器',
        audience: '受眾',
        industry: '類型',
        location: '地區',
        // country: '國家',
        country: '地區',
        stateregion: '州/地區',
        language: '語言',
        publisher: '媒體',
        campaignName: '廣告計劃',
        assetName: '素材名稱',
        adsize: '廣告尺寸',
        spend: '花費',
        viewedImp: '可視曝光數',
        click: '點擊',
        ctr: '點擊率',
        colAvgCpc: '平均CPC',
        colVcpm: 'vCPM',
        colDate: '日期',
        conversions: '轉化',
        conversionRate: '轉化率',
        conversionPrice: '轉化價格',
        colConversionPrice: '轉化價格',
        colConversionRate: '轉化率',
        colCtr: '點擊率',
        needAccount: '請選擇賬户',
        needCampaign: '請選擇廣告計劃',
        timePre: '數據上次更新',
        conversionTimePre: '轉換數據上次更新',
        timeAfter: '(報表的時區與您的廣告賬户的時區一致)',
        targetBid: '目標出價',
        fixedBid: '固定CPC',
        OCPC: 'OCPC',
        ECPC: 'ECPC'
    },
    amDashboard: {
        // 运营配置
        currentSsp: '目前投放SSP',
        // black&white
        kwTitle: '廣告帳戶層級排除或指定刊登位置設定及廣告活動層級關鍵字設定',
        kwSetting: '排除或指定刊登位置設定',
        kwTag: '關鍵字設定',
        filterType: '規則類型',
        // blackList: 'Black List',
        // whiteList: 'White List',
        insertList: '上傳關鍵字清單',
        selectCsv: '選擇一個CSV檔案',
        editDate: '修改時間',
        operationId: '修改者',
        objectName: '修改內容',
        insert: '上傳',
        edit: '修改',
        importWhite: '導入keyword白名單',
        importBlack: '導入keyword黑名單',
        kwWarning: '格式錯誤，請移除以下關鍵字後重新上傳',
        dialogTitle: '上傳內容',
        dialogSubTitle: '共上傳{num}個關鍵字',
        downloadCurrentCSV: '下載當前CSV文件',
        companyName: '公司名稱',
        // country: '國家',
        country: '地區',
        language: '語言',
        date: '日期',
        roas: 'ROAS',
        // remainingBudget: '廣告計劃餘額',
        remainingBudget: '活動剩餘預算',
        taboolaBind: 'Taboola廣告拉取',
        configableCpc: '可設定的CPC下限',
        accountListSetting: '賬戶列表設置',
        hideClosedAccount: '隱藏已關閉賬戶',
        hideBalanceAccount: '隱藏總餘額小於0 的賬戶',
        CPC: 'CPC',
        totalBudget: '總預算',
        dailyBudget: '天預算',
        budget: '預算',
        campaignName: '廣告計劃名稱',
        accountName: '廣告主名稱',
        status: '投放狀態',
        campaignStatus: '投放開關',
        spend: '本期收入',
        impression: '可視曝光數',
        click: '點擊',
        clickRate: '點擊率',
        startTime: '開始時間',
        endTime: '結束時間',
        iImpression: '曝光',
        cost: '成本',
        iCtr: 'iCTR',
        iCpm: 'iCPM (收入)',
        viewableRate: '可視率',
        vCtr: 'vCTR',
        colCfgTitle: '設置列',
        campaignId: '廣告計劃ID',
        accountId: '賬户ID',
        colStatus: '投放狀態',
        colStart: '開始時間',
        colEnd: '結束時間',
        colSpend: '本期收入',
        colTotalBudget: '總預算',
        colDailyBudget: '天預算',
        colDetail: '詳情',
        colDelivery: 'Delivery',
        colCpc: 'CPC',
        colAvgCpc: '平均CPC',
        colVCpm: 'vCPM (收入)',
        colImp: '可視曝光數',
        colClick: '點擊',
        colCtr: '點擊率',
        colConversion: '轉化',
        colConversionRate: '轉化率',
        colConversionPrice: '轉化價格',
        colIimpression: '曝光',
        colCost: '成本',
        colIctr: 'iCTR',
        colIcpm: 'iCPM (收入)',
        colViewableRate: '可視率',
        blackWhiteConfig: '配置黑白名單',
        colProfitRate: '利潤率（實際）',
        colSpendRate: '預算消耗比例',
        colLastSpend: '上期收入',
        colCompareRate: '收入對比',
        colAmResponsible: 'AM負責人',
        spendRemark: '收入對比 =（本期收入 - 上期收入）/上期收入',
        spendRateRemark: '預算消耗比例: 所選時間內該廣告的消耗/所選時間內該廣告的日預算總和。使用最新的日預算。',
        profitRateRemark: '利潤率（實際）=（當期收入 - 當期成本）/ 當期成本',
        totalCampaign: '所有廣告計劃',
        abnormalCampaign: '異常廣告計劃',
        campaignOptLog: '廣告計劃操作日誌',
        campaignOptCfg: '廣告計劃運營配置',
        pageIdBlockCfg: '廣告計劃PageId黑名單配置',
        campaignAlertCfg: '廣告計劃異常提醒',
        colIndustry: '投放品類',
        tradeCategory: '行業分類',
        icafe: {
            newItem: '新建事項'
        },
        serviceRecord: '廣告主運營記錄',
        minCpcRecordText: '最低出價從{old}修改為{new}',
        accountBlackWhiteConfigTitle: '帳戶層級的黑白名單設定',
        campaignBlackWhiteConfigTitle: '廣告活動層級黑/白名單',
        sellerIdBlackWhiteConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        sellerIdBlackWhiteConfigTips: '*The SellerID blacklist/whitelist will take effect, only when the Account-level media blacklist/whitelist is removed',
        rulesType: '規則類型',
        configIndex: '設定層級',
        configObject: '設定內容',
        blackList: '黑名單',
        whiteList: '白名單',
        mediaSiteGroup: '媒體網站Group',
        mediaAccountAndSite: '媒體帳戶/媒體網站',
        mediaAccount: '媒體帳戶',
        mediaSite: '媒體網站',
        selectedConfig: '設定記錄包括當前活動設定+ 添加的無效設定',
        rulesTypeTip: '無法跨類型添加黑/白名單。請在添加黑名單之前刪除所有白名單，並在添加白名單之前刪除所有黑名單。',
        deleteAll: '全部刪除',
        sitePlaceholder: '"為媒體帳戶批量新增站點。請輸入站點名稱，使用「;」分隔，使用「*」可作相似比對，否則將預設為精準比對。"',
        mediaPlaceholder: 'Supports adding accounts in batches for selected media accounts. Please enter account id, separated by semicolons.',
        sellerPlaceholder: 'Supports adding Seller ID in batches for selected accounts. Please enter Seller ID, separated by semicolons.',
        creativeIdPlaceholder: 'Content, separated by two semicolons.',
        cancel: '取消',
        confirm: '確認',
        operationReport: '操作報表',
        operationTime: '操作時間',
        userName: '操作使用者',
        actionDetails: '操作記錄',
        USA: '美國',
        Canada: '加拿大',
        UnitedKingdom: '英國',
        Australia: '澳大利亞',
        Japan: '日本',
        Korea: '韓國',
        Taiwan: '台灣',
        Indonesia: '印度尼西亞',
        Singapore: '新加坡',
        Malaysia: '馬來西亞',
        Thailand: '泰國',
        HongKong: '香港',
        from: '從',
        to: '修改為',
        tips1: '此OCPC功能與創建廣告出價中OCPC聯動，請謹慎調整。',
        tips2: '1）若Campaign出價為OCPC，廣告主將有所察覺，請謹慎調整。',
        tips3: '2）若Campaign出價為固定出價，此OCPC功能將不被廣告主察覺。若廣告主將固定出價修改為OCPC，此目標CPA將作為OCPC的預設目標CPA。',
        // 通知中心
        notificationCenter: '通知中心',
        createNotification: '建立新通知',
        notificationTitle: '標題',
        recipients: '收件人',
        noticeDate: '日期',
        startData: '开始时间',
        endData: '结束时间',
        notificationContent: '內容',
        confirmNotice: '確認',
        previousNotifications: '已發布通知',
        sender: '寄件人',
        submittedTime: '提交時間',
        dataRange: '日期範圍',
        notificationStatus: '狀態',
        changeStatusTip: '狀態調整為「關閉」後，將無法再「開啟」',
        msgTitleRequired: '*必填：標題',
        msgRecipientRequired: '*必填：收件人',
        msgLanguageRequired: '請選擇語言',
        msgContentRequired: '*必填：內容',
        msgTipsTitle: '【提醒】',
        msgStatusChangeTips: '狀態調整為「關閉」後，將無法再「開啟」。請問確定關閉此通知嗎？',
        close: '關閉',
        delete: '已讀',
        noMessage: '沒有新的通知',
        moreMessage: '更多通知',
        toNoticeList: '返回通知列表',
        noticeDetail: '通知內容',
        closeDetail: '返回',
        noticeListTitle: '通知列表'
    },
    pixel: {
        postback: {
            accountSelectPhd: '無可操作的帳戶',
            step1: {
                params: '將追蹤參數新增至登陸頁面',
                desc1: '如需詳細參數說明，請參閱',
                help: '說明文件',
                desc2: '',
                suggest: `
                    <div>如广告转化路径如下：</div>
                    <div>点击广告 --> Content Page --> Product Page(广告主网站) --> Thankyou Page (CV转化)。</div>
                    <div style="font-weight:500;">则需输入下列主域名，且所有页面需埋入初始化代码</div>
                    <div style="font-weight:500;">1.Content page主域名</div>
                    <div style="font-weight:500;">2.Product Page主域名</div>
                `,
                phase2Tip: '  (※请注意仅添加初始化代码并未完成转化创建，你仍需要根据下方指引完成创建。)'
            },
            step2: {
                params: 'S2S轉換回傳'
            },
            importantConversionTip: `
                <div style="line-height:20px;color:#2dd1ac">标记为重要转化，</div>
                <ul style="margin:0;padding-left:20px;color:#2dd1ac;line-height:20px;">
                    <li>该转化会被计入控制台的转化列（CV、CVR、CPA等）</li>
                    <li>算法（如Smartbid等）会根据该转化进行优化；</li>
                </ul>
            `,
            nonImportantConversionTip: `
                <div style="line-height:20px;color:#2dd1ac">标记为非重要转化，</div>
                <ul style="margin:0;padding-left:20px;color:#2dd1ac;line-height:20px;">
                    <li>该转化数据仍然会被记录，你可以在控制台的“设置列“中勾选对应字段查看，但转化列（CV、CVR、CPA等）不会计入这些数据</li>
                    <li>算法（如Smartbid等）不会根据该转化进行优化；</li>
                </ul>
            `,
            step4TipHeader: '请确保初始代码安装在事件代码之前',
            step4TipImg1Header: '如果想跟踪页面加载，请将事件代码片段添加到HTML的<head>元素中',
            step4TipImg2Header: '如果想跟踪内联操作，请将事件代码添加到相关HTML元素中。',
            viewFullCode: '查看完整代码',
            retractFullCode: '收起完整代码',
            conversionDesc1: '當使用者點擊含有您追蹤代碼的廣告時，Discovery會提供 TRACKING_ID 到您的伺服器。請使用包含 ${TRACKING_ID} 值的 URL 來傳送轉換資料。',
            conversionDesc2: '回傳 URL 的格式：',
            example: '請注意，您需要以實際值取代參數，例如：',
            isIncludeConversion: '包含在總轉換數內',
            lastTime: '最近回傳時間',
            postbackReceived: '收到的轉換數',
            trackingLP: '落地頁URL',
            testTime: '測試連結時間',
            copyTestUrl: '複製測試連結',
            createTestUrl: '產出測試連結',
            createS2Spostback: '建立S2S postback',
            createCreativesTip: '請確認追蹤代碼中包含 ${TRACKING_ID} 以確保可追蹤轉換。',
            testCvPostback: '測試轉換回傳',
            pleaseSelectAcc: '請先選擇正確的帳戶名稱',
            testCvEvent: '測試轉換事件',
            postbackList: '回传转化列表',
            lpUrlParams: '帶有追蹤參數的登陸頁面URL',
            testUrl: '測試連結',
            cvPostback: '回传转化',
            copyToBrowser: '复制测试链接至浏览器中',
            addCartTip: '在落地页中完成一次您设定的转化事件，如加购、注册、安装应用等',
            checkLpLink1: '未偵測到 ${TRACKING_ID}。要追蹤轉換，您必須在登陸頁面 URL 中包含 ${TRACKING_ID}參數。請參閱',
            checkLpLinkHelp: '說明中心',
            checkLpLink2: '，瞭解如何進行此操作。',
            copySuccess: '複製成功，請貼上瀏覽器。',
            copyError: '复制失败，请稍后重试。',
            getCvPostbackSuccess: '連結已成功產出，請複製連結。',
            getCvPostbackError: '產出失敗，請稍後再試。',
            lpUrlParamsTip: '添加广告素材 复制 落地页URL 或 编辑广告计划 复制落地页URL',
            tckTitle: '輸入活動追蹤的 UTM 代碼，例如：',
            campaignName: '${CAMPAIGN_NAME}，廣告系列標籤中定義的廣告系列名稱',
            adTitle: '${AD_TITLE}，廣告標題',
            contentName: '${CONTENT_NAME}，此廣告的關鍵字',
            tckId: '${TRACKING_ID}，伺服器對伺服器回傳追蹤的參數。',
            utmInfoHeaderTip: '如果您使用 S2S 回傳追蹤，請在追蹤代碼中保留此參數。',
            moreParams: '了解更多',
            linkTckPreview: '帶有追蹤參數的<br>登陸頁面URL',
            linkTckPreviewTip: '可複製連結以測試S2S postback是否成功傳回轉換。',
            copyLink: '複製連結',
            copyLinkTip1: '複製成功。請前往 ',
            copyLinkTip2: 'S2S postback 頁面',
            copyLinkTip3: '測試是否成功接收轉換。',
            testCvEventDesc: '請產出測試連結，並在測試連結頁面中觸發您設定的轉換事件',
            copyToBrowserDesc: '複製並貼上轉換測試連結到瀏覽器，並在測試連結頁面中觸發指定的轉換事件，例如加入購物車、註冊或安裝應用程式。',
            cvType: '轉換類型',
            testCvPostbackDesc: '完成轉換後，您需要將帶有 ${TRACKING_ID} 值的 postback url 傳回轉換資訊',
            testRecordTable: '測試記錄表',
            testRecordTableDesc: '收到轉換回傳時顯示 <span style="vertical-align: middle" class="msn-fa msn-fa-postback-green-right"></span>，未收到時顯示 <span style="vertical-align: middle" class="msn-fa msn-fa-postback-gray-right"></span>。最多可能延遲 30 分鐘。'
        },
        companyName: '公司名称',
        selectDimensionTip: '請選擇帳戶/公司',
        accountPlaceholder: '請選擇相應帳戶',
        companyPlaceholder: '請選擇相應公司',
        pixelCreateTipHeader: '在選定帳戶/公司下配置初始Pixel、MCV Event Pixel和CV Event Pixel用於轉換追蹤',
        pixelCreateTipContent: '若僅對應一個帳戶埋設pixel，請選擇帳戶名稱；若同一公司主體下多個帳戶需要埋設pixel，請選擇公司名稱，可無須多次埋設代碼',
        createRead1: '請複製以下Code並將其放在 </ body> 位置之前。',
        createRead2: '安裝完成後請檢查是否正常觸發，建議至少保留2個工作日的檢查時間，以避免影響廣告投放走期。',
        createRead3: 'READ嵌入Code',
        getREADCode: '建立READ Code',
        codeType: 'Code類型',
        urlBased: 'URL',
        eventBased: '事件',
        type: '類型',
        accountName: '賬户名稱',
        pixelName: '初始Pixel代碼名稱',
        conversionType: '轉化類型',
        status: '狀態',
        includeInTotalConversion: '重要轉化',
        lastReceived: 'Last Received',
        lastModified: '最後修改時間',
        eventName: '事件名稱',
        category: '類型',
        optionalVal: '轉化價值',
        optionalValAppend: '用於為自己的轉化添加轉化價格之類的信息。',
        conversionWindow: 'Window系統轉換',
        isIncludeTatalConversion: '重要轉化',
        isExcludeCampaigns: '從所有廣告計劃中排除受眾',
        urlBasedTip: 'URL 基準: 每次用戶訪問一個url符合特定條件的網站，就會上報一次',
        pixelReadTip: '如廣告轉換路徑為下：<br>點擊廣告 → Content Page → Product Page(廣告主網站) →Thankyou Page (CV轉換)。<br>★ 則需輸入下列網域，且所有頁面皆須埋入Code<br>1.Content page 主網域<br>2.Product Page 主網域',
        eventBasedTip: 'Event 基準：js code埋點統計，每一次用戶做了一個特定動作，例如加入購物車等，就會上報一次',
        urlCondition: 'URL條件',
        urlConditionAppend: 'url滿足以下條件之一即會上報:',
        conversionProperties: '轉化屬性',
        conversionName: '轉化名',
        conversionNameAppend: '自定義一個唯一的名字，輔助你區分此轉化數據',
        conversionCategory: '轉化分類',
        conversionCategoryAppend: '選擇此轉化所屬類別',
        importantConversion: '重要轉化',
        importantConversionTipTitle: '如果選擇是，',
        importantConversionTip1: '控制台等轉換統計會計入這部分數據',
        importantConversionTip2: '算法會根據這部分轉化數據優化',
        eventCategory: '選擇事件類型',
        eventCategoryAppend: '選擇需要觸發轉化的事件',
        embedHtmlCode: '追蹤嵌入代碼',
        embedHtmlCodeAppend: '將事件添加到對應事件觸發時的代碼位置',
        pixelInitialCode: 'Pixel初始化代碼',
        pixelInitialInfo: 'Phase 2: 將此段Tag埋入到Landingpage及中間轉跳頁中html的 <head>標籤中。<br>請注意僅添加初始化代碼並未完成轉化追蹤安裝，你仍需要點擊創建轉化按鈕、根據指引完成轉化追蹤安裝。',
        copy: '複製',
        create: '創建轉化',
        condition: '條件',
        or: 'OR',
        newCondition: '新條件',
        newConditionAppend: '添加url全等或包含條件，計入轉化',
        confirmCreate: '創建轉化',
        initPixel: '查看MediaGo 初始 Pixel',
        mcvCvOther: 'MCV/CV/其它',
        initPixelTab: '初始Pixel',
        initPixelPopin: '查看Discovery 初始 Pixel',
        viewCode: '查看代碼',
        conversionEdit: '編輯轉化',
        step1: 'Step 1',
        step1Desc: '添加Pixel初始化代碼',
        step1Desc1: ' (如果已經添加，請跳過).',
        step2: 'Step 2',
        step2Desc: '選擇類型',
        step3: 'Step 3',
        step3Desc: '轉化屬性',
        step4: 'Step 4',
        pixelInitialInfo1: 'Phase 2: 將此段Tag埋入到Landingpage及中間轉跳頁中html的 <head>標籤中。',
        headerCode: 'Phase 1：請輸入Landingpage及中間轉跳頁的主網域名',
        headerCode1: '（※如果廣告路徑含有跨不同域名的情況，請以"換行"的方式，輸入所有路徑的域名',
        pixelInitialTitle1: 'Phase 1：請輸入Landingpage及中間轉跳頁的主網域名<br>（※如果廣告路徑含有跨不同域名的情況，請以"換行"的方式，輸入所有路徑的域名',
        expandCode: '展開創建並複制初始化代碼',
        // new pixel conversions
        createNewPixel: '建立新Pixel',
        howInstallPixel: '如何安装Pixel？',
        downloadDoc: '下載文件',
        pixelCreateSubTitle: '在已選擇帳戶下建立初始Pixel、MCV Event Pixel和CV Event Pixel用於轉換追蹤',
        selectAccount: '選擇帳戶',
        configInitPixel: '建立初始Pixel',
        addPixelCode: '添加代碼',
        mcvEventPixel: 'MCV Event Pixel',
        hasConfigText: '已建立：',
        pixelNumUnit: '條',
        cvEventPixel: 'CV Event Pixel',
        otherEventPixel: '其它Event pixel',
        addInitialPixel: '添加初始Pixel',
        initCodeTips: '*請確保初始Pixel準確安裝在全站廣告網頁最上方的<head>中。請填入主域名，如有跨域名情況，請以「換行」方式輸入所有主域名。',
        pixelCodeTips: '*請確保初始Pixel準確安裝在全站廣告網頁最上方的<head>中。',
        pixelPlaceholder: '如廣告連結LP1、LP2和OP的主域名分別為a.com、b.com和c.com，請依據下方方式輸入\na.com\nb.com\nc.com',
        getInitialPixel: '取得初始Pixel',
        editUpdateText: '編輯修改',
        initPixelDone: '我已完成初始Pixel代码添加',
        saveAndCopy: '複製並保存',
        setMcvEventPixel: '建立MCV Event Pixel',
        mcvEventTips: '*請確保MCV Event Pixel準確安裝在落地頁後第一個頁面的最上方<head>中。',
        getMcvEventPixel: '取得MCV Event Pixel',
        copyMcvPixelTips: '*請將本段代碼安裝在落地頁後第一個頁面的最上方<head>中。',
        mcvPixelDone: '我已完成MCV Event Pixel添加',
        setCvEventPixel: '建立CV Event Pixel',
        cvEventCodeTips: '*請確保CV Event Pixel準確安裝在推廣目標完成頁面或按鈕上。',
        getCvEventPixel: '取得CV Event Pixel',
        copyCvPixelTips: '*請將本段代碼安裝在推廣目標完成頁面或按鈕上。',
        cvPixelDone: '我已完成CV Event Pixel添加',
        setOtherEventPixel: '建立其它Event pixel',
        otherEventTips: '*請確保Event Pixel準確安裝在對應事件觸發時的代碼位置。',
        copyOtherPixelTips: '*請將本段代碼安裝在對應事件觸發時的代碼位置。',
        getOtherEventPixel: '取得其它Event pixel',
        otherPixelDone: '我已完成其它Event Pixel添加',
        trackingCodeTips: '*追蹤嵌入代碼將事件添加到對應事件觸發時的代碼位置',
        manageExistPixel: '管理現有Pixel',
        initPixelTips: '請將初始Pixel安裝在全站廣告網頁最上方的<head>中。',
        mcvPixelTips: '請將MCV Event Pixel安裝在Landing Page 2最上方的<head>中。',
        cvPixelTips: '請將CV Event Pixel安裝在推廣目標完成頁面或按鈕上。',
        hasDeprecated: '（無法使用）'
    },
    // 權限部分
    permissionMgr: {
        accountId: '賬户id',
        accountStatus: '賬户狀態',
        accountName: '賬户名',
        companyName: '公司名',
        type: '公司性質',
        email: '開户人信箱',
        payMethod: '支付方式',
        adMethod: '投放方式',
        timezone: '時區',
        createAccount: '新建賬户',
        inviteLink: '開户邀請鏈接',
        copyLink: '複製邀請鏈接',
        dailyCmpCount: '帳戶API單日建立campaign上限',
        dailyCmpCount2: '',
        openDailyCmpCount: 'Open Account API Single-Day Creation Campaign Upper Limit',
        dailyLimit: '開啟時，帳戶使用API​​單日可建立500條Campaign，單一CPN下最多可建立10個asset。',
        roleMgr: {
            detail: '展開詳情',
            edit: '權限管理',
            del: '刪除'
        }
    },
    permission: {
        notAuthorizeTip: '非Admin角色不可被授權該功能',
        p1: '請點擊同意MediaGo的隱私政策和服務條款以繼續。',
        password: '密碼',
        newPassword: '新密碼',
        timeZone: '時區',
        inputVCode: '驗證',
        vCode: '發送驗證碼',
        forgotPasswordInputVCode: '驗證碼',
        forgotPasswordVCode: '發送驗證碼',
        title: '廣告主開户',
        subtitle: '以下是您的用户信息。還有2步，即可進入。',
        userId: '用户id',
        accountType: '賬户類型',
        quizz1: '1. 請問您所在的公司是否已經開户？',
        yes: '已開户',
        no: '未開户',
        quizzyes: '2. 請點擊“發送郵件”按鈕，進行權限申請。',
        quizzno: '2. 請點擊“發送郵件”按鈕，進行開户和權限申請',
        sendEmail: '發送郵件',
        email: {
            title: '【用户申請表】',
            divider: '===============================================================',
            title1: '申請權限 + 開户',
            bodyTitle1: 'MediaGo MSN申請權限',
            bodySubtitle1: '(注意請補充完整空白字段)',
            bodyTitle2: 'MediaGo MSN申請廣告賬户並賦予權限',
            bodySubtitle2: '(注意請補充完整空白字段)',
            account: {
                title: '【開户表】',
                company: '* 公司主體名稱: ',
                registerAddr: '* 公司註冊地址: ',
                operationAddr: '* 公司經營地址: ',
                type: '* 公司性質: ',
                officialSite: '* 官網鏈接: ',
                position: '* 開户人職位:',
                owner: '* 開户人信息:',
                ownerInfo: '(請輸入您的信箱)',
                accountNum: '* 開户數量:    1個 ',
                accountNumInfo: '(您只能申請1個賬户。)',
                accoutName: '* 賬户名稱:  ',
                totalCap: '* 賬户總預算:    $ ',
                totalCapInfo: '(單位: 美元)',
                chargeType: '* 支付方式:  ',
                chargeTypeInfo: '(請輸入“預付/到付”)',
                manner: '* 投放方式:   ',
                mannerInfo: '(請輸入“自投/代投”)'
            },
            permission: {
                title: '【用户申請表】',
                name: '* 暱稱:',
                nameInfo: '(請輸入真實姓名-職位。如：段瑩-PM)',
                userId: '* 用户id: ',
                accountType: '* 賬號類型: ',
                userType: '* 用户類型:  ',
                userTypeInfo: '(請輸入：百度員工/廣告主/代理商) ',
                email: '* 信箱: ',
                emailInfo: '(請輸入您的信箱)',
                company: '* 公司主體名稱: ',
                adAccount: '* 廣告賬户名稱: ',
                adAccountInfo: '(請輸入您公司所開賬户的名稱。) '
            },
            result: {
                info: '感謝您的申請，',
                info1: '我們會盡快聯繫您。',
                footer1: '如有其他問題，請聯繫:',
                footer2: '我們會盡快回復'
            }
        },
        emailReport: {
            create: '創建報告',
            status: '狀態',
            acountName: '賬户名稱',
            timeZone: '報告時區',
            recivieName: '收件人',
            frequency: '發送頻率',
            closeTips: '關閉後就不再發送',
            zoneTips: '與賬户時區一致',
            emailFormat: '可輸入多個信箱，用逗號隔開',
            day: '每日',
            month: '每月'
        },
        formTitle: '註冊MediaGo賬號',
        formDesc: '填寫以下信息，我們會盡快聯繫您幫您開放平台權限。',
        formAppend: '點擊申請，你將同意MediaGo的',
        formAppendLink: '隱私協議',
        // country: '國家',
        country: '地區',
        currency: '結算幣種',
        firstName: '名字',
        lastName: '姓氏',
        formEmail: '工作信箱',
        formEmailTip: '該信箱將被用做用户名',
        submitProtocol: '是的！我願意收到定製化的廣告優化建議',
        timeZoneTip: '選擇一個你的投放時區，確定後將無法修改',
        currencyTip: '選擇你的結算幣種，選定後不可修改',
        organizationName: '公司名稱',
        companySite: '公司網址',
        jobTitle: '職位',
        phone: '電話',
        companyNameandtips: '公司註冊名稱（註冊時填寫）',
        apply: '立即開始',
        createAccount: '建立',
        tologinText: '已有{platform}賬號'
    },
    openAccount: {
        stepOne: '廣告賬户申請',
        stepTwo: '綁定用户',
        stepThree: '申請完成',
        workEmailTip: ' (該信箱是您在MediaGo賬號的用户名）',
        billingEmail: '財務結算信箱',
        billingEmailSymbol: '請使用英文\';\'分割符號',
        billingEmailLength: '長度不能超過1000個字符',
        billingEmailTip: '若有固定接受賬單的信箱，請填寫至該輸入框，信箱之間用 ; 隔開。',
        accountTitle: '廣告賬户開户申請表',
        accountTitleDesc: '以下信息僅用於開户申請，我們不會將您的信息泄露出去',
        accountTitleDesc1: '您提供的信息將僅用於賬號申請。',
        currency: '結算幣種',
        // 幣種提示
        currencyTitle: '選擇你的結算幣種，選定後不可修改',
        // 交易币种提示
        tradingCurrencyTitle: '選擇你的交易幣別，選定後不可修改。',
        // 合同提示
        contractualTip: '選擇賬户的合同主體',
        accountTitleDesc2: '由於您填寫的信息將直接影響結算，請填寫與官方登記備案文件中一致的公司信息。',
        accountName: '賬户名稱',
        accountEmail: '開户人信箱',
        totalBudget: '賬户總預算',
        payMethod: '支付方式',
        prepayment: '預付',
        delivery: '到付',
        timezone: '賬户時區',
        adMethod: '投放方式',
        selfService: '自投',
        managed: '代投',
        companyName: '公司主體名稱',
        registerAddr: '公司註冊地址',
        operateAddr: '公司經營地址',
        website: '官網鏈接',
        userTitle: '平台用户綁定',
        userTitle1: '廣告用户申請',
        userTitleDesc: '一個廣告賬户可綁定多個用户，填寫以下信息添加或註冊MediaGo賬號',
        addExistAccount: '添加已有賬號',
        registAccount: '註冊新賬號',
        // country: '國家',
        country: '地區',
        firstName: '名字',
        lastName: '姓氏',
        email: '工作信箱',
        jobTitle: '職位',
        phone: '電話號碼',
        submitAppend: '點擊申請，你將同意MediaGo的',
        privacyLink: '隱私協議',
        terms: '條款',
        logout: '登出',
        del: '刪除',
        add: '添加',
        apply: '申請',
        formAppendLink: '隱私協議',
        info: '感謝您的申請，',
        info1: '我們會盡快聯繫您。',
        footer1: '如有其他問題，請聯繫:',
        footer2: '我們會盡快回復',
        invalidToken: '此鏈接無效，請聯繫你的AM重新獲取鏈接',
        inviteMsg: '邀請你在MediaGo開户'
    },
    validations: {
        campaignObjective: '廣告目標不能為空',
        couponAmount: '请输入需要设置的Coupon金额',
        effectiveTime: '请选择生效时间',
        targetMax: '仅支持输入整数，且最小输入值为100.',
        validNumber: '請輸入有效數字',
        validDecimal: '只能保留3位小數點',
        simpleRequired: '必填',
        jobTitle: '請輸入有效的職位名稱',
        firstName: '請輸入有效的名',
        lastName: '請輸入有效的姓',
        required: '$field不能為空',
        number: '請輸入有效數字',
        money: '請輸入有效金額',
        moneyNum: '目標出價必須是數字',
        moneyJpy: '單日預算必須為整數',
        moneyNumJpy: '目標出價必須是整數',
        moneyTotalJpy: '總預算必須為整數',
        numberBeyond: '數字不能大於1,000,000,000',
        numberBeyondJpy: '單日預算不能少於￥2,000/天',
        dailyCapBeyond: '單日預算不能少於{money}/天',
        cpcBeyond: '為優化廣告效果，最低出價不能低於{money}',
        checkCurrencyMaxCPC: 'CPC出價上限{money}',
        // numberBeyondJpy: '單日預算不能少於￥2,000/天',
        numberBeyond1: '數字不能大於1,000,000',
        dailyOverTotal: '單日預算不能超過總預算',
        dailyOverTotal2: '單日預算不能超過總預算',
        dailyOverTotal3: '總預算不能小於單日預算',
        dailyOverTotal4: '單日預算不能少於$20/天',
        dailyOver20: '單日預算不能少於$20/天',
        lifetiemUnderDaily: '總預算不能小於單日預算',
        targetBidOverDaily: '目標出價不能超過單日預算',
        targetBidOverDailyJpy: '目標出價必須為整數',
        targetBidOverDaily2: '目標出價不能超過單日預算',
        startAfterEnd: '起始時間不能晚於結束時間',
        start: '起始時間不能為空',
        end: '結束時間不能為空',
        endBeforeStart: '結束時間不能早於開始時間或當前時間',
        status: '狀態不能為空',
        daily: '單日預算不能為空',
        lifetime: '總預算不能為空',
        targetBid: '目標出價不能為空',
        // create
        image: '請上傳一張合適的圖片',
        imageRevise: '請重新上傳合適的圖片',
        imageInfo: '請上傳一張{scale}px的圖片',
        size: '尺寸不能為空',
        http: '請輸入帶有"http://"或者"https://"協議的有效鏈接',
        charLenth: '不能超過250個字符',
        charLen: '不能超過{num}個字符',
        revise: '請修改審核不通過的值',
        name: '名稱不能為空',
        platform: '平台不能為空',
        os: '系統不能為空',
        browser: '瀏覽器不能為空',
        adFormat: '廣告格式不能為空',
        placement: '廣告位不能為空',
        audience: '受眾不能為空',
        industry: '類型不能為空',
        location: '州不能為空',
        zipcode: '郵編不能為空',
        brandName: '品牌名不能為空',
        headline: '標題不能為空',
        adAssetName: '素材名稱不能為空',
        imageValidations: 'Image is required',
        adAssetNameNotRepeat: '素材名稱不能重複',
        landingPage: '網頁鏈接不能為空',
        impURL: '第三方追踪鏈接不能為空',
        landingPageError: '網頁鏈接中不能包含空格 ，以及多個？',
        noAds: '沒有可以審核的廣告',
        skipInfo: '跳過後，內容會丟失。確認跳過？',
        leaveInfo: '離開後，內容會丟失。確認離開？',
        reviewNotComplete: '未審核完成',
        advertiser: '請選擇一個廣告賬户',
        utmParams: '廣告追蹤不能包含?, @, /, 空格以及換行符',
        picDuplicated: '不要上傳相同的圖片',
        // 登錄
        email: '請輸入有效的信箱地址',
        organizationName: '請輸入組織名稱',
        companySite: '請輸入公司網址',
        phone: '請輸入電話號碼',
        switchLang: '切換語言會導致填寫的數據丟失，確定切換嗎？',
        confirmToSwitch: '有未保存的編輯。切換語言之前要保存編輯嗎?',
        confirmTip: '发布操作不可取消，请点击确认按钮以继续。',
        saveTip: '是否保存当前操作？请点击相应按钮以继续。',
        cacheNewCampaign: '你要繼續編輯之前的廣告計劃嗎？',
        cacheContinue: '繼續編輯',
        cacheAgain: '重新開始',
        fileParse: 'ファイル解析中、送信ボタンをクリックしてください。5 ~ 15分後に新しいダッシュボードでクリエイティブが正常に作成されたか確認できます。',
        cacheSaveNewCampaign: '離開當前頁面後，系統會保留已編輯的內容，可在下次新建廣告時繼續編輯。',
        pwd: '密碼至少為8位，需包含數字、字母和符號',
        pwdNotMatch: '兩次輸入的密碼不一致',
        relation: '請選擇過濾大小關係',
        pwdRequired: '請輸入有效的密碼',
        // open account
        company: '請輸入正確的公司名',
        companyName: '請輸入正確的公司名',
        account: '請輸入賬户名稱',
        budget: '請輸入預算',
        registerAdd: '請輸入正確的公司註冊地址',
        registerAddress: '請輸入正確的公司註冊地址',
        businessAddress: '請輸入正確的公司運營地址',
        timezone: '請選擇賬户時區',
        // country: '請選擇國家',
        country: '請選擇地區',
        payMethod: '請選擇支付方式',
        adMethod: '請選擇投放方式',
        vCodeNeed: '請輸入正確的驗證碼',
        // 權限
        noUser: '沒有可以授權的用户',
        errorCampaign: '無權限的操作',
        errorUploadImgSize: '上傳圖片大小不能超過 500K!',
        blackWhiteType: '請選擇黑名單或白名單',
        sspRequire: '請選擇媒體帳戶',
        // pixel
        accountRequired: '請選擇賬户',
        conversionNameRequired: '請輸入轉化名',
        categoryRequired: '請輸入類型',
        valueRequired: '請輸入轉化價值',
        eventTypeRequired: '請選擇事件類型',
        repeatEmail: '信箱不能重複',
        emailNotExist: '信箱不存在',
        selectUser: '請填寫需要綁定的用户',
        cpcInfo: '目標出價只能保留3位小數點',
        cpcJpyInfo: '目標出價只能保留1位小數點',
        targetCpaInfo: '目標CPA只能保留2位小數點',
        cpcInfoTarget: '目標出價未更改',
        cpcNumber: '目標出價必須是數字',
        invalidFile: '郵編添加失敗，請根據模版上傳正確的CSV文件',
        sensitiveTag: '敏感標籤不能為空',
        cpcPercentMin: '輸入框數值不能低於-99',
        cpcNumberInteger: '請輸入整數',
        currencyCheck: '請選擇賬户結算幣種',
        tradingCurrencyCheck: '请选择账户交易币种',
        legalRepresentativeCheck: '請選擇以下選項之一',
        contractCheck: '請選擇賬户合同主體',
        cpcMin: '仅支持输入整数，且最小输入值为10.',
        cpcMax: '仅支持输入整数，且最小输入值为100.建议比例上限在150%以上.',
        cpcMaxUp: '上限数值须大于下限数值。',
        cpaType: '请选择CPA类型'
    },
    currencySpecies: {
        dollar: 'USD（$）',
        bahtDollar: 'THB（฿）',
        japaneseDollar: 'JPY（￥）',
        RMB: 'CNY（CNY ￥）',
        taiwanDollar: 'TWD（NT$）',
        HKDollar: 'HKD（HK$）',
        singaporeDollar: 'SGD（S$）',
        wonDollar: 'KRW（₩）',
        malaysianRupiah: 'MYR（RM）',
        indonesianRupiah: 'IDR（Rp）'
    },
    infos: {
        info: '提示',
        total: '總計',
        emptyText: '暫無數據',
        emptyReadChartText: '沒有設置READ代碼，無法顯示數據',
        allSelect: '全選',
        selected: '已選',
        dragTip: '（拖放以重新排序）',
        conversionTip: '請求MediaGo回傳鏈接查看轉化數據，{}。或在Pixel菜單下設置MediaGo Pixel，{}。轉化數據的更新會有約2個小時的延遲。',
        DiscoveryConversionTip: '請求Discovery回傳鏈接查看轉化數據{}。或在Pixel菜單下設置Discovery Pixel，{}。轉化數據的更新會有約2個小時的延遲。',
        conversionTipGo: '前往',
        conversionTipMore: '查看詳情',
        postBack: '回傳鏈接:',
        sessionInvalid: '系統已更新，請刷新頁面重試',
        fileSizeLimit: '為优化廣告效果，建議圖片分辨率大於600*400像素'
    },
    constants: {
        all: 'All',
        cancel: '取消',
        selectAll: '全選',
        eq: '相等',
        gt: '大於',
        lt: '小於',
        ge: '大於等於',
        le: '小於等於',
        platform: '平台',
        os: '系統',
        browser: '瀏覽器',
        smartphone: '智能手機',
        mobile: '移動設備',
        desktop: '電腦',
        tablet: '平板',
        xbox: 'Xbox',
        android: '安卓',
        ios: 'iOS',
        macOs: 'Mac OS',
        windows: 'Windows',
        linux: 'Linux',
        chrome: 'Chrome',
        firefox: 'Firefox',
        safari: 'Safari',
        ie: 'Internet Explorer',
        samsung: 'Samsung',
        opera: 'Opera',
        uc: 'UC Browser',
        inApp: 'InApp',
        edge: 'Edge',
        est: 'EST',
        utc0: 'UTC+0',
        utc7: 'UTC+7',
        utc8: 'UTC+8',
        usdname: 'USD',
        jpyname: 'JPY',
        cnyname: 'CNY',
        sgdname: 'SGD',
        myrname: 'MYR',
        krwname: 'KRW',
        idrname: 'IDR',
        thbname: 'THB',
        twdname: 'TWD',
        hkdname: 'HKD',
        utc9: 'UTC+9',
        standard: '勻速',
        accelerate: '加速',
        other: '其他',
        daily: '天',
        hourly: '小時',
        earlier: '早於',
        later: '晚於',
        at: '當天',
        white: '白名單',
        black: '黑名單',
        msn: 'MSN',
        mgid: 'MGID',
        // 合同主體
        mediago: 'mediago',
        jpSubject: 'jp.popin.cc',
        krSubject: 'kr.popin.cc',
        twSubject: 'tw.popin.cc',
        docomo: 'docomo',
        au: 'au',
        softbank: 'softbank',
        male: '男',
        female: '女',
        // 受众
        car: '汽車',
        election: '選舉類',
        entertainment: '娛樂類',
        money: '金融借貸類',
        finance: '財務金融類',
        foodDrink: '食物和飲品類',
        homepage: '首頁類',
        health: '健康類',
        lifestyle: '生活類',
        music: '音樂類',
        movies: '電影類',
        news: '新聞類',
        sports: '運動類',
        travel: '旅行類',
        TV: '電視類',
        video: '影音類',
        weather: '天氣類',
        education: '教育類',
        gadgetsAutos: '工具和汽車類',
        business: '商業類',
        beautyFashion: '美容與時尚類',
        economyMoney: '經濟與金融類',
        homeLifestyle: '家居與生活類',
        loveMarriage: '愛情與婚姻類',
        politicsSocial: '政治與社會類',
        travelGourment: '旅遊與美食類',
        autoselectronics: '汽車與電子類',
        // 广告分类
        auto: '汽車類',
        beautyFitness: '美容與健身類',
        beautyHairCare: '美容護髮類',
        businessAndFinance: '商業和金融類',
        careers: '職業生涯類',
        computerElectronics: '計算機與電子類',
        eCommerce: '電子商務類',
        eventsAndAttractions: '活動和景點類',
        familyAndRelationships: '家庭和關係類',
        financeInsurance: '金融保險類',
        food: '食品類',
        gaming: '遊戲類',
        Gaming: '遊戲類',
        healthcare: '衛生保健類',
        homeGarden: '家居與園藝類',
        newsAndPolitics: '新聞政治類',
        pets: '寵物類',
        personalCareOralCare: '個人護理-口腔護理類',
        popCulture: '流行文化類',
        realEstate: '房地產類',
        religionSpiritualty: '宗教信仰類',
        science: '科學類',
        travelHospitality: '旅游與飯店類',
        weightLoss: '瘦身減重類',
        wellness: '健康類',
        others: '其他',
        content: '內容',
        leadGen: '潜在顧客',
        search: '搜索類',
        insurance: '保險類',
        recruitment: '招聘類',
        marriage: '婚姻類',
        medicalIndustry: '醫療行業類',
        diet: '飲食類',
        beautyCosmetic: '美容化妝品類',
        homeAppliances: '家用電器類',
        computer: '電腦相關類',
        communication: '通訊類',
        media: '媒體類',
        game: '遊戲類',
        physicalEducation: '體育類',
        beautyCosmetics: '美妝類',
        industry: '類型'
    },
    requestMsg: {
        requestError: '獲取數據失敗，請稍後再試',
        exportError: '導出數據失敗，請稍後再試',
        exportSuccess: '導出成功！',
        statusUpdated: '狀態更新成功',
        updatedFailed: '數據更新失敗',
        createError: '創建失敗',
        uploadFailed: '上傳失敗，稍後再試!',
        uploadOverSize: '文件不能超過{num}MB',
        uploadSuccess: '上傳成功',
        gifSizeInfo: 'gif圖片尺寸無效，請重新上傳合適的圖片',
        logoutSuccess: '登出成功',
        logoutFailed: '登出失敗',
        modified: '您的密碼已修改成功！',
        loginFailed: '賬號或密碼錯誤',
        copySuccess: '複製成功',
        previewFirst: '請先預覽'
    },
    backendMsg: {
        fail: '失敗',
        success: '成功',
        sendCodeSucs: '發送驗證碼成功',
        alreadyExist: '該信箱已被使用，請更換信箱地址',
        verificateFail: '請輸入正確的驗證碼',
        busy: '服務器繁忙，請稍後再試',
        noPermission: '無權限的操作',
        gifError: 'GIF的尺寸和card不匹配',
        loginError: '用户名或密碼錯誤',
        registError: '信箱重複',
        repeatCreate: '請不要重複創建廣告',
        operateTooMuch: '過於頻繁的操作',
        amountError: '金額不能小於0',
        accountExist: '賬户已經存在',
        companyExist: '公司已經存在',
        campaignNameExist: '同賬户內廣告計劃名不可重複',
        paramsError: '參數錯誤',
        addRepeat: 'Cannot Add Repeatedly',
        landingpageOrUtmIsInvalid: 'landingPage或者是utm校驗失敗',
        conversionExist: '轉化名已存在',
        requestError: '獲取數據失敗，請稍後再試',
        linkInvalid: '無效token',
        roleExist: '角色名已存在',
        noResult: '該驗證碼已超時，或者該信箱未發送驗證碼',
        ecommercePageNameRepeated: '頁面名稱已存在，請輸入其他名稱',
        ecommerceSeoUrlRepeated: 'SEO URL 已經存在',
        auditTypeNameExist: '审计类型名称已经存在',
        tagExist: '标签已存在',
        readExist: '該Read code已存在',
        completedDataAccumulation: '此活動已完成數據搜集，無法使用一鍵繼承功能',
        sizeTooLarge: '圖片檔案大小上限：5MB',
        gifSizeTooLarge10: '最大GIF大小不能超過10MB',
        imgTypeError: '僅支援JPG/JPEG/PNG/GIF等圖片檔案格式'
    },
    // paypal充值
    paymentList: {
        time: '時間',
        method: '付款方式',
        actualAmount: '實際金額',
        status: '付款狀態',
        operate: '詳情',
        prepaidAmount: '付款金額',
        id: '付款ID',
        transferFee: '手續費',
        accountName: '廣告帳號',
        payer: 'Paypal賬戶',
        receipt: '收據',
        totalCoupon: '有效Coupon總額',
        couponBalance: 'Coupon餘額',
        totalQuotaTip: '總可用額度 = 累計充值 + Credit Line + 有效Coupon總額 - 累計花費',
        fold: '收起賬户數據',
        expand: '展開所有賬户數據',
        recharge: '充值',
        autoBilling: '自動充值',
        balanceLess0: '該賬户總可用額度不足，請{充值}後再行創建廣告。'
    },
    // stripe支付
    stripeList: {
        status: '狀態',
        account: '廣告帳號',
        method: '付款方式',
        date: '上次付款日期',
        amount: '上次付款金額',
        spend: '帳號花費',
        card: '付款信用卡號',
        ABAmount: '自動扣除金額',
        edit: '编辑',
        paymentDetails: '付款詳情',
        history: '付款紀錄'
    },
    // geoEdge
    geoEdge: {
        type: '觸發類型',
        time: '觸發時間',
        detail: '詳情',
        detailLink: '詳情鏈接'
    },
    // bidAnalysis
    bidAnalysis: {
        adId: '廣告ID',
        placementId: '平台ID',
        avgRank: '排名',
        bidMode: '競價模式',
        picpm: 'P-ICPM',
        pvcpm: 'P-VCPM',
        pictr: 'P-ICTR',
        pvctr: 'P-VCTR',
        pvwr: 'P-VWR',
        pvctrLevel: 'P-VCTR水平',
        pvwrLevel: 'P-VWR水平',
        cpc: 'CPC',
        campaignId: '廣告計劃ID',
        title: '廣告標題',
        linkTo: '競價分析'
    },
    // 電商
    'nativeE-commerceTool': {
        createFormAccountName: '賬戶名稱',
        createFormProductName: '產品名稱',
        createFormSampleLPUrl: '落地頁樣本鏈接',
        createFormParseBtn: '解析',
        createFormPageName: '頁面名稱',
        createFormPageNameLength: '長度不能超過300個字符',
        createFormImages: '圖片',
        createFormImagesBtn: '查看全部圖片',
        createFormreplaceKeyword: '替換關鍵詞',
        createFormreplaceKeyword1: '全部替換',
        createFormreplaceKeyword2: '至',
        createFormAddRules: '添加規則',
        createFormMatchCase: '相符',
        createFormCTAURL: 'CTA URL.',
        createFormSEOURL: 'SEO網址。',
        createFormSeoTagTitle: 'SEO 標題',
        createFormseoDescription: 'SEO 描述',
        createLPBtn: '添加新落地頁',
        createOPBtn: '添加新 OP',
        parseSuccess: '解析成功',
        parseFail: '解析失敗',
        searchLPFilter: '搜索頁面名稱',
        searchButton: '搜索',
        productMultipleChoice: '產品只允許單選',
        coppyOPHTML: '複製 HTML',
        productChoice: '請選擇產品',
        opCreatedPageName: '請輸入頁面名稱',
        coppyLPLinkButton: '複製鏈接',
        selectDomain: '選擇域名',
        addTemplate: '添加郵件模板',
        searchtemplate: '搜索郵件模板',
        emailTemplateName: '郵件模板名稱',
        status: '狀態',
        domain: '域名',
        emailAddress: '郵件地址',
        emailPassword: '密碼',
        lastModifiedDate: '最後修改日期',
        creationUser: '創建人',
        lastModifiedUser: '最後修改人',
        operation: '操作',
        domainName: '域名名稱',
        templateName: '模板名稱',
        conditions: '條件',
        storeLogo: '店鋪圖標',
        storeName: '店鋪名稱',
        emailTitle: '郵件標題',
        emailContent: '郵件內容',
        emailImage: '郵件圖片',
        emailFooter: '腳註',
        addNewEmailTemplate: '添加郵件模板',
        editEmailTemplate: '編輯郵件模板',
        copyEmailTemplate: '複製郵件模板',
        deleteEmailTemplate: '刪除郵件模板',
        recommendedSize: '推薦尺寸'
    },
    supplement: {
        blockSiteDataSuffix: '屏蔽site資料後綴',
        blockSiteDataSuffixTip: '開啟時，該帳戶及其活動屏蔽site資料後綴。',
        bulkUpload: '批量上傳',
        regionEurope: '地區:GE、FR、IT、ES、AT',
        editCompany: '編輯公司',
        agentAccount: '代理商帳號',
        selectAgentAccount: '請選擇代理商帳號',
        externalWebsite: '外部網址填寫公司資訊',
        change: '修改',
        editAccount: '編輯帳戶',
        addAccount1: '新增帳戶',
        请输入内容: '請輸入內容',
        openingInformation: '開帳戶資訊',
        agencyPublisher: '若選擇賬戶性質為代理商,則一經選擇不可修改',
        specifiedSSP: '指定SSP',
        operationType: '操作類型',
        accountManager1: '帳戶/管理人',
        progressInDescription: '進度內容',
        descriptionOfCurrentProgress: '目前進度內容',
        currentProgress: '請輸入目前進度內容',
        pleaseSelect: '請選擇',
        quantityAllotted: '分配數量',
        discoveryOnline: 'Discovery線上合約',
        seleced: '已選',
        editRemark: '修改備註',
        bid: '出價',
        noBid: '不出價',
        fixedBid: '固定出價',
        smartBid: '智慧出價',
        noAdBid: '無待審核的廣告出價',
        EntNumber: '請輸入-10到10之間的數值',
        trainPrice: '出價學習模式',
        floorPrice: '最低出價',
        maxPrice: '最高出價',
        limitCPC: '最低CPC',
        atLeast: '至少需要1小時至一個工作天',
        noCreateAPI: '該帳戶不符合串接API條件',
        eventArea: '請選擇活動區域',
        pleaseenterrecipientemail: '請填寫收件人信箱',
        billingInformation: '帳單資訊',
        cooperationMode: '合作模式',
        viewRS: '查看RS修改記錄',
        setRS: '設定新RS',
        onceEnter: '填寫後不可修改',
        billingCycle: '帳單結算週期',
        financialEmail: '財務信箱',
        sendInvoice: '使用於發送Invoice資訊，可添加多個財務信箱，用『 ; 』分隔即可',
        mediaInformation: '媒體訊息',
        trafficType: '流量類型',
        adTool: '廣告工具',
        addUser1: '增加使用者',
        userBackground: '使用者背景',
        authorizationConfig: '權限設定',
        roleConfig: '角色設定',
        remenberUser: '已成功增加使用者，請紀錄以下使用者帳戶密碼',
        userName: '使用者帳號',
        userPassword: '使用者密碼',
        advertiserLogin: '已成功增加使用者，請使用廣告主平台密碼登錄',
        done: '完成',
        accountORcompany: '輸入帳戶/公司名',
        selectRole: '請選擇角色',
        selectBMaccount: '請選鑿BM帳戶',
        selectWritable: '請選擇可編輯帳戶',
        selectReadable: '請選擇可檢視帳戶',
        accAuthorization: '帳戶授權',
        enterAmountCoupon: '請輸入欲儲值的Coupon金額',
        enterBM: '請輸入商務經理',
        enterIndustryClassification: '請輸入產業分類',
        enterIndustry: '請輸入產業',
        selectAM: '請輸入所屬AM',
        selectContractType: '請選擇合約主體',
        selectAccountLanguage: '請選擇帳戶語言',
        selectCompanyType: '請選擇公司類型',
        addressCompany: '請輸入公司營運地址',
        enterAddress: '請輸入公司註冊地址',
        enterValid: '請輸入有效的Rebate%',
        selectAppropriate: '請選擇代理商帳號',
        onHold: '待審核',
        cVROPC1: '此CVR優化與建立廣告出價中的',
        cVROPC2: 'OCPC連動，請慎重調整。',
        cVROPC3: '1）當campaign出價為固定出價，則此CVR優化功能，廣告主不會有感知。如果廣告主將固定出價修改為 OCPC，此 CVR 優化設定將作為 OCPC 的默認設定。',
        cVROPC4: '2）當campaign出價為OCPC，此CVR優化設定與OCPC連動，廣告主可透過報表查查看，請慎重調整',
        algorithmic: '優化目標對象',
        changedAfterSetting: '設定後不可更改',
        cpa: '必須大於0',
        completed: '已完成',
        pleaseEnter1: '請輸入',
        profitRatio: '利潤率必須數字',
        yueBao1: '(內部資料，請勿外流)',
        yueBao3: '操作紅線，素材圖片和詳細數據指標僅在內部查看，不得與廣告商分享',
        dailySubsidy: '補量天級虧損上限必須為不小於0的數字',
        totalSubsidy: '補量總虧損上限必須為不小於0的數字',
        accelerate: '加速訓練倍數必須是介於 0-20 之間的整數',
        impBound: '加速訓練倍數必須是介於 0-10000之間的整數',
        contains: '包含',
        percentage: '佔比',
        creation: '新建',
        processing: '處理中',
        accepting: '待確認',
        clear: '清除',
        mon: '星期一',
        tue: '星期二',
        wed: '星期三',
        thu: '星期四',
        fri: '星期五',
        sat: '星期六',
        sun: '星期日',
        selected: '已選擇',
        unselected: '未選擇',
        less1: '由於 Stripe 收取手續費，儲值到MediaGo的金額將小於 ',
        less2: '。',
        whenRecharge: '當帳戶花費金額達到設定的預算，Stripe會自動從您儲存的信用卡中扣除固定金額，自動扣除金額可在自動儲值中設定。',
        whenAmount: '當賬戶消費到您在賬戶消費金額中設置的金額時，Stripe將自動從您在其自動扣款中保存的信用卡中扣除您在此處設置的金額。請注意，設置的自動充值金額不能低於設置的賬戶消費金額。',
        autoBillingAmount: '自動扣除金額',
        paymentDetails: '付款詳情',
        dueAmount: '扣除手續費後，實際收到的金額將少於付款金額。',
        selectAdAccount: '選擇廣告帳號',
        minimum: '最少儲值金額為 $50',
        transferAmount: '手續費全額付款（包含PayPal費用）',
        paypal1: '為確保 MediaGo 收到',
        paypal2: '的全額款項，您需要付款',
        paypal3: '以支付 PayPal 收取的轉帳費用。',
        amountPayable: '應付金額',
        transferFee: '手續費',
        rechargeAmount: '儲值金額的2.9%~4.9% + 每筆交易 $0.3',
        actualAmount: '實際金額 ',
        cancel: '取消',
        payNow: '立即付款',
        paymentHistoryDetails: '付款紀錄詳情',
        paymentAccount: '付款帳戶',
        rechargeAmount1: '儲值金額',
        amountSpend: 'Amount Spend',
        account: '帳戶',
        PayPalmore1: '請注意，PayPal的標準匯款費用為匯款金額的2.9%，加上每筆匯款 0.30 美金。想了解更多詳情，',
        PayPalmore2: '請點擊這裡。',
        automaticBilling: '自動儲值',
        automaticBilling1: '開啟此功能後，Stripe 將自動保存您的信用卡信息，以便下次自動扣款。',
        monthlyOperationalReport: '運營月報',
        currentIncome: '本期收入',
        percentageOfConsumption: '花費佔比',
        lastPeriodIncome: '上期收入',
        yearOnYearLastWeek: '與上週比較',
        yearOnYear: '與上期比較',
        comparisonFunction1: '對比功能使用tips:',
        comparisonFunction2: 'Step1:左側選擇本期時間區間',
        comparisonFunction3: 'Step2:右側選擇對比的起始時間',
        display: '展開並顯示圓餅圖數據',
        comparison: '對比',
        categoryOfEvent: '事項類別',
        adAccount: '廣告帳號',
        category: '行業分類',
        campaignName: '廣告計劃名稱',
        priority: '優先順序',
        status: '狀態',
        accountManager: 'AM負責人',
        currentPersonInCharge: '現任負責人',
        addTracker: '新建事項',
        uploadImage: '上傳圖片',
        blackFormat: '屏蔽格式',
        blackPageType: '屏蔽頁麵類型',
        forstyle: '樣式過濾，多個值之間用英文“;”分隔。例如：infopane;river',
        forpage: 'pagetype的過濾，多個值之間用英文“;”分隔。例如：edgechrmtp;25;36',
        startDate: '開始日',
        accountSource: '帳戶來源',
        accountNature: '帳戶類別',
        contractTitle: '合同主體',
        aPIToken: 'APIToken',
        serviceType: '服務類型',
        businessManagement: '商務管理',
        advertiser: '廣告主',
        aPIhasinstalled: '已設置API',
        withoutSettingAPI: '未設置API',
        openAccountIndependently: '自主開戶',
        inviteToOpenAccount: '邀請開戶',
        searchName: '搜尋帳戶/公司名稱',
        previewImgTitle: '清除',
        accountName: '帳戶名稱',
        accountID: '帳戶ID',
        companyName: '公司名稱',
        // country: '國家',
        country: '地區',
        billingEmail: '財務結算信箱',
        organizationName: '公司組織名稱',
        businessRebate: '商務返點',
        email: '開戶人信箱',
        paymentMethod: '付款方式',
        deliverymethod: '投放方式',
        accountTimeZone: '帳戶時區',
        businessManager: '商務經理',
        industryCategory: '行業分類',
        creationTime: '建立時間 (EST)',
        operate: '操作',
        accountDetails: '帳戶詳情',
        accountInformation: '帳戶資訊',
        organizationWebsite: '官網鏈接',
        accountHolderInformation: '開戶人信息',
        totalAccountBudget: '帳戶總預算',
        timeZone: '時區',
        company: '公司',
        mediaGoNon: 'MediaGo非對接公司',
        registeredAddressOfCompany: '公司註冊地址',
        companyAddress: '公司經營地址',
        emailaddress: '請輸入信箱',
        prepay: '預付',
        postpay: '到付',
        mark1: '賬戶創建API的條件:1.賬戶性質為廣告主(二次確認)2.該賬戶狀態為開啟狀態3.該賬戶的消耗達到5000美金',
        generateToken: '生成Token',
        mark2: '注:投放在Taboola的廣告無法使用API拉取數據',
        addUserRW: '新增用戶將自動為該用戶開啟該帳戶的可檢視權限',
        otherUsers: '其他用戶',
        operator: '操作人',
        operatingTime: '操作時間',
        specificIndustry: '具體行業',
        add: '添加',
        automaticReporting: '創建報告',
        reportTimeZone: '報告時區',
        recipient: '收件人',
        sendingFrequency: '發送頻率',
        stopsending: '關閉後就不再發送',
        consistent: '與賬戶時區一致',
        mark4: '可輸入多個信箱，用逗號隔開',
        daily: '每日',
        monthly: '每月',
        advertiserName: '廣告主名稱',
        advertiserEmail: '廣告主信箱',
        advertiserApplication: '廣告主申請',
        loginInfo: '登錄信息',
        applicationCompleted: '完成申請',
        postpay1: '後付',
        accountType: '帳戶類別',
        selfService: '自投',
        managed: '代投',
        reportingTimezone: '報告時區',
        selectthetimezone: '選擇時區來管理您的廣告活動。您的設置無法修改。',
        organizationName1: '機構名稱',
        asthiswil: '由於此變更將影響計費方式，請準確地輸入詳細的公司組織資訊',
        organizationAddress: '機構地址',
        asthiswilldirectly: '由於此變更將影響計費方式，請準確地輸入詳細的公司組織資訊',
        organizationWebsite1: '組織網站',
        initialCreditLine: '初始信用額度',
        ifyou: '如果您將初始信用額度設置為大於 0，則會向 AM 組和您發送通知電子郵件。',
        advertisernameisrequired: '廣告商名稱為必填項”',
        registeraddressisrequired: '註冊地址為必填項',
        pleaseEnter: '請輸入以 http: 或 https: 開頭的有效公司網站',
        workEmail: '工作電子郵件',
        workEmail1: '(這將用作用戶名）',
        firstName: '名',
        lastName: '姓',
        password: '密碼',
        pleaseenteravalidemailaddress: '請輸入有效的信箱地址',
        pleaseenteravalidfirstname: '請輸入有效的名',
        pleaseenteravalidlastname: '請輸入有效的姓',
        specialcharacterandnumber: '請輸入至少 8 個字符，混合字符、特殊字符和數字',
        next: '下一頁',
        previous: '上一頁',
        submit: '送出',
        officialWebsite: '官網鏈接',
        pleaseenteryouremail: '請輸入信箱',
        pleaseentercompanyURL: '請輸入公司網址',
        pleaseentertheaccountname: '請輸入帳戶名稱',
        pleaseenterbudget: '請輸入預算',
        deliveryMethod: '投放方式',
        pleaseselectacompany: '請選擇公司',
        addCompany: '新增公司',
        ifthere: '若沒有，請新增公司後，再選擇',
        customerSource: '客戶來源',
        terminalclient: '直客',
        agent: '代理商',
        contractType: '合同主體',
        contractType2: '合同主體',
        adding: '新增用戶將自動為該用戶開啟該帳戶的可檢視權限',
        pleasechooseanotherrole: '請選擇其他用戶角色',
        registeredaddressofthecompany: '公司註冊地址',
        companyBAddress: '公司經營地址',
        bysubmitt: '提交此表格，即表示我同意 MediaGo 的隱私政策和條款。',
        authorizedUser: '已授權用戶',
        unauthorizedUser: '未授權用戶',
        name: '名字',
        usersource: '用戶來源',
        loginMethod: '登錄方式',
        workEmail2: '工作信箱',
        companyName1: '公司名稱（平台錄入名稱）',
        position: '職位',
        numberOfWritableAccounts: '可編輯賬戶數量',
        numberOfReadableAccounts: '可檢視賬戶數量',
        role: '角色',
        searchCompanyName: '搜索公司名稱+姓名+信箱',
        userInvitationLink: '用戶邀請鏈接',
        details: '詳情',
        userRegistrationInformation: '用戶註冊信息',
        userSource: '用戶來源',
        lastName1: '姓氏',
        name1: '名字',
        companyWebsite: '公司網址',
        telephone: '電話',
        userRole: '用戶角色',
        accountAllocation: '帳戶分配',
        bMAccount: 'BM帳戶',
        writableAccount: '可編輯帳戶',
        readableAccount: '可檢視帳戶',
        addingordeletinga: '對BM帳戶執行新增刪除變更，將影響該BM用戶下所有代理商帳戶的可檢視權限。請謹慎操作！',
        edit: '編輯',
        ordinal: '序號',
        roleName: '角色名稱',
        assignedNumber: '分配人數',
        changeTime: '修改時間',
        createRole: '創建角色',
        pleaseentertherolename: '請輸入角色名稱',
        mediaGoAdvertiser: 'MediaGo廣告主',
        baiduReviewer: '百度審核員',
        microsoftReviewer: 'Microsoft 審核員',
        baiduRD: '百度RD',
        baiduBD: '百度BD',
        baiduAM: '百度AM',
        financialStaff: '財務',
        admin: '管理員',
        dataAnalyst: '數據分析員',
        mGIDReviewer: 'MGID審核員',
        parentologyReviewer: 'Parentology審核員',
        helpCenter编辑: '使用說明編輯',
        financialReceivable: '財務應收',
        roleManager: '角色管理員',
        prebidTestReviewer: 'Prebid 測試審核員',
        rTBDemandReviewer: 'RTB審核員',
        zipporReviewer: 'Zippor審核員',
        adPushupReviewer: 'AdPushup審核員',
        advertiser1: '代理商廣告主',
        pubGeniusReviewer: 'PubGenius審核員',
        insticatorReviewer: 'Insticator審核員',
        streamAmpReviewer: 'StreamAmp審核員',
        financialStaffPaypal: '財務結算-Paypal',
        eCommerceAM: '電商AM',
        eCommerceROIReport: '電商ROI報表',
        adminR: '查看權限管理員',
        internalTestAdvertiser2: '內測廣告主2',
        offlineProject: '財務-offline項目',
        bulkReview: '批量審核',
        domainWhitelist: 'mobile domain白名單',
        financialReport: '財務報表',
        stripeAutoBilling: '內測廣告主-stripe自動支付',
        discoveryAdvertiser: 'Discovery廣告主',
        discoveryFinancalTest: 'Discovery財務測試',
        discoveryOldAdvertisers: 'Discovery舊廣告主',
        discoveryOldAgency: 'Discovery舊代理店廣告主',
        oldDiscoveryAgency: 'Discovery舊代理店',
        discoveryNewadvertisers: 'Discovery新廣告主',
        platformExperienceRead: '平台體驗專用-Read',
        bidbydomain: '分域名出價',
        newDiscoveryAdvertiser: '新Discovery廣告主',
        dashboard: '控制台',
        reviewDetails: '審核詳情',
        campaignDetails: '廣告活動詳情',
        campaignCopy: '複製廣告計劃',
        campaignEdit: '廣告活動編輯',
        adStatus: '廣告投放狀態',
        domainBlock: '域名屏蔽',
        domainTabsspColumn: 'domain頁ssp屬性',
        reviewDetails1: '審核詳情媒體分類',
        adColumnSize: 'ad設置列 尺寸 選項',
        dimensionFilter: '多維度篩選',
        conversiontype: '轉換類型',
        oCPC: 'OCPC',
        domainSetCampaignCpc: 'Bid By Domain',
        eCPC: 'ECPC',
        adOperation: '運營平台',
        analysis: '運營分析',
        blackwhiteconfig: '設定黑白名單',
        campaignOperationConfig: '廣告計劃運營配置',
        taboolaAdPull: 'Taboola廣告拉取',
        blacklistconfig: '廣告計劃PageId黑名單配置',
        reminder: '廣告計劃異常提醒',
        log: '廣告計劃操作日誌',
        advertiserOperationRecord: '廣告主運營記錄',
        multiDimensionalreport: '多維度數據報表',
        tracker: '事項追踪',
        bidAnalysis: '競價分析',
        diagnosis: '廣告診斷',
        innerFAQ: '',
        sample: '抽樣平台',
        newCampaign: '創建廣告',
        win10adTargeting: 'win10定向投放',
        userPackage: '用戶包',
        more: '多素材',
        // domainwhitelist: '域名白名單',
        impURL: '第三方追蹤',
        // locationJapan: 'locationJapan',
        // monthlyCapStatus: '月預算',
        // objective: '目標',
        // audience: '受眾',
        pixel: 'Pixel',
        conversionlist: 'Conversion列表',
        readlist: 'Read列表',
        newConversion: '創建轉換',
        dataReport: '數據報表',
        lPManagement: '內容管理',
        oPManagement: 'OP Management',
        productManagement: '產品管理',
        domainManagement: '域名管理',
        customerManagement: '客戶管理',
        rOIAnalysis: 'ROI報表',
        paymentManagement: '帳款管理',
        reviewAds: '審核廣告',
        startReviewing: '開始審核',
        detail: '詳情',
        category1: '類別',
        geoEdge: 'Geo Edge',
        sensitiveCategory: '敏感類別',
        auditLabel: '審核標籤',
        displayallauditedimg: '一次性展示所有審核圖片',
        authorization: '權限管理',
        accountList: '帳戶管理',
        addAccount: '建立帳號',
        link: '新建帳戶邀請連結',
        authorize: '授權',
        ignore: '忽略',
        copyInvitationLink: '複製邀請連結',
        bDAccountOpening: 'BD建立帳戶',
        userList: '用戶列表',
        roleList: '角色管理',
        delete: '刪除',
        create: '建立',
        billing: '帳戶儲值',
        recharge: '儲值',
        stripe: 'Stripe',
        autoBilling: '自動儲值',
        multiCurrencySwitcher: '多貨幣切換',
        finance: '財務管理',
        creditLine: '財務管理',
        creditLine1: 'Credit Line',
        coupon: 'Coupon',
        rOIReport: '財務報表',
        receivable: '應收款',
        payable: '應付款',
        offline: 'offline',
        bannerViewableTest: 'Banner可視化測試',
        viewableTest: '可視化測試',
        hibernation: '休眠狀態',
        aDCallback: '廣告召回狀態',
        adBidConfig: '廣告位出價配置',
        review: '審核',
        history: '歷史記錄',
        generateLPWrapper: '生成落地頁網頁',
        getEmail: '管理員獲取信箱驗證碼',
        write: '可編輯',
        read: '可檢視',
        currencyType: '結算貨幣類型',
        open: '自主開戶',
        inviteTo: '邀請開戶',
        uSD: '美元',
        cNY: '人民幣',
        jPY: '日元',
        sGD: '新加坡元',
        hKD: '港幣',
        tWD: '台幣',
        tHB: '泰銖',
        iDR: '印度尼西亞盾',
        kRW: '韓元',
        mYR: '令吉',
        totalBalance: '綜合收支',
        totalRecharge: '儲值金額',
        availablequota: '可用額度',
        creditLineSetting: 'Credit Line設置',
        creditLineRecord: 'Credit line 修改記錄',
        rechargeRecord: '除職紀錄',
        pleaseentertheamounttorecharge: '請輸入要儲值的金額',
        validAmount: '請輸入有效數值',
        asof: '截止於',
        remark: '備註',
        pleaseenteraremark: '請輸入備註',
        uTSDataUpdateTime: 'UTS數據更新時間',
        totalCoupon: 'Coupon總額',
        couponConsumption: 'Coupon已消耗',
        effectiveCouponBalance: '有效Coupon餘額',
        effectiveTime: '生效時間',
        expireDate: '到期時間',
        offlineTime: '下線時間',
        profitMarginSetting: '利潤率設置',
        pleaseenterprofitmargin: '請輸入利潤率',
        profitMarginRecord: '利潤率修改記錄',
        currentIncome1: '當期收入',
        currentCost: '當期成本',
        currentProfit: '當期利潤',
        profitMargin: '利潤率',
        impression: '展示數',
        clicks: '點擊',
        totalHistory: '全部歷史紀錄',
        totalHistoricalIncome: '總收入紀錄',
        totalHistoricalCost: '總成本紀錄',
        historicalProfitMargin: '利潤率紀錄',
        lastUpdateTime: '上次更新時間',
        enterAccountCompanyName: '輸入帳戶公司名稱',
        media: '投放媒體',
        currentIncome2: '目前收入=帳戶目前消耗（尚未扣除Coupon）',
        pullAds: '拉取廣告',
        interfaceSetting: '接口配置',
        adInterface: '廣告接口',
        testField: 'test字段',
        campaignId: '廣告計劃ID',
        aDId: '廣告ID',
        onlineEnvironment: '線上環境',
        testEnvironment: '測試環境',
        developmentEnvironment: '開發環境',
        testNoBilling: '1-測試-不計費',
        realBilling: '0-真實-計費',
        multipleids: '多個id，用英文逗號分隔。例：111,222',
        multipleids1: '多個id，用英文逗號分隔。例：111,222',
        pageParameters: '頁面參數',
        placement: '平台',
        pageURL: '頁面URL',
        category2: '類型',
        adTypeSetting: '廣告樣式配置',
        thereserveprice: '底價，不填則默認0。 eg:0.43',
        fixedIndex: '固定index：英文逗號分隔，不填默認index',
        geographicalConfig: '地理位置配置',
        zipCode: '郵遞區號',
        iP: 'IP',
        language: '語言',
        unitedStatesState: '美國-州',
        noneMSNMayNotTransmit: '無-MSN可能不傳',
        stateData: '各州數據',
        deviceInformation: '設備信息',
        userAgent: '用戶代理',
        personalInformation: '個人信息',
        microsoftUID: 'Microsoft UID',
        copymuid: '複製muid',
        refreshmuid: '刷新muid',
        fuzzyMatch: '模糊匹配',
        sspId: 'SSP ID',
        selectRecallStatus: '召回狀態篩選',
        notrecallable: '不可召回',
        recallable: '可召回',
        inquire: '查詢',
        column: '設置列',
        campaign: '廣告計劃',
        ad: '廣告',
        generate: '生成',
        getVerificationCode: '驗證碼獲取',
        verificationCode: '驗證碼',
        none: '無',
        obtain: '獲取',
        pid: 'Pid',
        priceStatus: '出價狀態',
        trainPriceStatus: '訓練價格狀態',
        sSPId: 'Ssp Id',
        imageSize: '圖片大小',
        format: '格式',
        pidProfit: 'Pid 利潤',
        cTRSwitch: '點擊率開關',
        priceMode: '出價模式',
        toBeReviewed: '待評審',
        belowReservePrice: '低於底價',
        reset: '重置',
        search: '查詢',
        addPid: '新建Pid',
        startReview: '開始評審',
        adId: '廣告 ID',
        mediaAccount: '媒體',
        mediaAccountName: '媒體名稱',
        site: '站點',
        creativeID: '廣告素材 ID',
        browser: '瀏覽器',
        platform: '平台',
        oS: '操作系統',
        stateRegion: '州地區',
        cost: '成本',
        revenue: '收入',
        click: '點擊',
        vCPM: 'VCPM',
        cTR: '點擊率',
        avgCPC: '平均CPC',
        reqAdNum: '廣告請求數',
        reqNum: '請求數',
        resNum: '響應數',
        conversions: '轉換數',
        conversionRate: '轉換率',
        conversionPrice: '轉換價格',
        viewContent: '查看內容',
        appInstall: '安裝應用',
        completeRegistration: '完成註冊',
        addToCar: '加入購物車',
        addPaymentInfo: '添加付款信息',
        startCheckout: '開始結帳',
        purchase: '購買',
        addToWishlist: '加入願望清單',
        lead: '潛在用戶',
        other: '其他',
        addTitle: '添加標題',
        benchmark1: 'Benchmark篩選規則：',
        benchmark2: '分品類導出各品類下所有的Campaign數據作為原始數據',
        benchmark3: '對Campaign原始數據進行篩選',
        benchmark4: '保留審核通過的Campaign（排除拒審，歸檔）',
        benchmark5: '保留本月Vimp大於1W，且Clicks大於100，且Spend大於$100的Campaign',
        benchmark6: '將剩下的Campaign分品類進行CPC，和CTR的排序，得出CPC和CTR範圍',
        benchmark7: '優秀素材篩選規則：',
        benchmark8: '分品類導出各品類下所有的Campaign數據作為原始數據',
        benchmark9: '對Campaign原始數據進行篩選',
        benchmark10: '保留審核通過的Campaign（排除拒審，歸檔）',
        benchmark11: '分品類篩選出各品類下本月消耗Top10的Campaign',
        benchmark12: '對Top Campaign下的Ad進行篩選，篩選出vimp大於2W的Ad',
        benchmark13: '分品類挑選Top Ad：確保creative不重複，如果Creative重複，則排名順延',
        benchmark14: 'Content：將篩選出的Ad統一根據vctr排序，挑選vctr排序Top10的Ad。',
        benchmark15: 'E-commerce：將篩選出的Ad根據cvr和vctr分別排序',
        benchmark16: '挑選出cvr排序Top5的Ad',
        benchmark17: '挑選出vctr排序Top5的Ad',
        benchmark18: 'Finance：將篩選出的Ad統一根據vctr排序，挑選vctr排序Top10的Ad。',
        locationJapan: '日本地區',
        monthlyCapStatus: '月預算',
        domainwhitelist: '域名白名單',
        objective: '目標',
        audience: '受眾',
        remarkisrequired: '備註不能為空',
        amountRequired: '請輸入完整的需要調整的金額',
        addUser: '建立用户',
        privacyPolicy1: '提交此表格，即表示我同意MediaGo 的',
        privacyPolicy2: '隐私政策',
        privacyPolicy3: '和',
        privacyPolicy4: '条款',
        privacyPolicy5: '。',
        previous1: 'Thank you for registering!',
        previous2: 'If you have any further questions,',
        previous3: 'please contact',
        previous4: 'We’ll reply to you as soon as possible.',
        startAuthorizing: '開始授權',
        searchbyAccountType: '搜索賬戶類型',
        youImageHere: '您的圖片位置',
        targetMCPA: '目標MCPA',
        targetCPA: '目標CPA',
        confirmSubmission: '提交后，算法优化对象不可更改。确认提交？',
        accountName1: '帳戶名稱',
        campaginID: '廣告計劃ID',
        currentManager: '目前管理人',
        addRole: '添加角色',
        loginAddress: '登入地址',
        permanentlyDelete: '目前操作將永久刪除該角色，請點擊『繼續』',
        goon: '繼續',
        verifyThat: '確定產出 API token',
        applicationTitle: '完成申請，請保留以下廣告登入訊息',
        noApecificAccount: '無特定帳戶',
        noMoreThan: '不得超過',
        pleaseSelectItemCategory: '請選擇類別',
        pleaseSelectStatus: '請選擇狀態',
        pleaseSelectPriority: '請選擇優先度',
        pleaseSelectResponsiblePeople: '請選擇目前負責人',
        theAbnormalExposure: '曝光異常',
        blackFormat1: '請輸入Black Format',
        blackPlacement: '請輸入Black Placement',
        keyGoal: '主要目標長度不可超過100個字元',
        advertisingCategory: '投放產品長度不可超過100個字元',
        communicationChannel: '溝通渠道不可超過100個字元',
        otherInfo: '其他訊息長度不可超過200個字元',
        expireTime: '請選擇失效時間',
        profitRate: '請輸入需要修改的利潤率',
        amount: '請出入需要儲值的金額',
        editCreditline: '請輸入需要設定的信用額度',
        recordValue: '目前信用額度',
        totalRecharge1: '累計儲值金額',
        cannotBeModified: '確認後將無法修改，是否確認？',
        checkNumber: '請輸入有效的數字',
        password1: '至少一個大寫/小寫英文字',
        password2: '至少一個特殊標點符號',
        password3: '至少一個數字',
        password4: '總共至少8個英文/符號/數字組合',
        yueBao2: '廣告文案推薦',
        undef: '未定義',
        affiliate: '網盟',
        affiliateName: '網盟名稱',
        billingPeriod: '帳單結算週期',
        conversionDeduction: '扣除轉換',
        income: '收入',
        offerId: 'Offer ID',
        offerName: 'Offer 名稱',
        benchmark: '基準',
        content: '內容',
        ecommerceCtr: '電商CTR',
        ecommerceCvr: '電商CVR',
        'lead gen': '潛在客戶',
        noMore: '優化紀錄內容不可超過800個字元',
        noUndef: '優化紀錄內容不得為空白',
        comparison1h: '1h對比',
        comparison24h: '24h對比',
        comparison1w: '1w對比',
        now: '當前',
        optimizationType: '請設定優化目標，完成後無法再變更',
        generateAnalysisReport: '產出分析報表',
        reportEmail: '（報表將寄至您登入的信箱）',
        confirmSendingReport: '確認寄送報表',
        lineChart1: '沒有可顯示的數據',
        lineChart2: '請選擇廣告ID和Placement ID以查看數據',
        searchPlacementID: '查詢 Placement ID',
        sampleId: 'Sample ID',
        sampleName: 'Sample 名稱',
        bucketId: 'Bucket ID',
        description: '描述',
        conf: '配置',
        type: '類型',
        flow: 'flow',
        correctFormat: '請輸入正確格式，範例：12-20',
        noNumberLR: '左邊或右邊並非數字！',
        defaultHomePage: '預設首頁',
        homePage: '首頁',
        articlePage: '文章頁',
        galleryPage: '圖庫頁',
        videoPlayerPage: '影片播放頁',
        kids: '兒童類別',
        forGood: 'For Good',
        basePrice: '最低價格，未填入將預設為0，範例：0.43',
        fixedIndex1: '固定索引：使用英文逗號分割，未填入將預設索引遞增為1，範例：2,3',
        'river-half': 'river-half',
        river: 'river',
        rivertall: 'rivertall',
        'river-dbl': 'river-dbl',
        infopane: 'infopane',
        flexaside: 'flexaside',
        copyDone: '複製完成',
        checkStatus1: 'CPC Cap 詳細內容',
        checkStatus2: '日花費',
        checkStatus3: '日Cap',
        checkStatus4: '總花費',
        checkStatus5: '總Cap',
        checkStatus6: '特殊廣告活動不能設定休眠模式',
        adStatus1: '廣告已開啟',
        dormancy: '休眠模式 ON｜OFF',
        recallStatus: '可調用狀態',
        clickCapStatus: 'CPC Cap 狀態',
        impCapStatus: 'CPM Cap 狀態',
        under: '預算未用完',
        full: '預算用盡',
        timeStatus: '活動排程狀態',
        platoonPeriod: '活動排程中',
        outside: '活動排程已結束',
        uniformlyStatus: '標準狀態',
        uniformlyStatus1: '標準速度投遞並在控制中',
        campaignStatus: '廣告活動狀態',
        allRejected: '全部拒絕',
        allThrough: '全部通過',
        'Approved+pendingPeview': '通過 + 待審核',
        'Pass+Reject': '通過 + 拒絕',
        currentMediaPass: '當前媒體通過',
        currentMediaPendingReview: '當前媒體待審核',
        currentMediaRejection: '當前媒體拒絕',
        totalAccountBalanceStatus: '帳戶總餘額狀態',
        accountStatus: '帳戶狀態',
        auditPassSsps: '通過審核的媒體',
        index: '索引',
        newAdvertisingSpace: '新增廣告位置',
        json: '輸入框可使用json格式',
        json1: '（可同時輸入多個）',
        json2: '請輸入正確的JSON數字',
        pidProfit1: '第{i}行 Pid Profit值不能大於 10或者小於 -10',
        beforeTheChange: '更改前',
        afterTheChange: '更改後',
        modifier: '修改者',
        modifiedTime: '修改時間',
        newURL: '新連結',
        taboolaLP: 'Taboola LP',
        pageId: 'Page Id',
        creationDate: '建立日期',
        product: '產品',
        onLp: '是否開啟 LP 保留彈出窗口',
        onBanner: '是否開啟右側固定Banner',
        addNewProduct: '新增產品',
        editProuduct: '編輯產品',
        deleteProduct: '刪除域名',
        addNewDomain: '新增域名',
        editDomain: '編輯域名',
        domainId: '域名ID',
        favicon: '圖標',
        operations: '操作',
        tips: '建議尺寸：90*90',
        tips1: '圖片格式 請使用JPG / JPEG / PNG 格式！',
        tips2: '圖片尺寸需小於2MB！',
        enterDomainName: '請輸入域名',
        enterValidDomainName: '請輸入有效域名',
        pleaseUploadPictures: '請上傳圖片',
        pleaseEnterFooter: '請上傳Footer',
        emailTemplate1: '完成訂單後',
        emailTemplate2: '和',
        emailTemplate3: '訂單',
        domain: '請選擇域名',
        emailAddress: '請輸入信箱',
        emailAddress1: '請輸入正確的信箱',
        enterPassword: '請輸入信箱密碼',
        templateName: '請輸入信箱模板名稱',
        time: '請選擇時間區間',
        storeLogo: '請上傳商家LOGO',
        storeName: '請輸入商家名稱',
        emailTitle: '請輸入信件標題',
        emailContent: '請輸入信件內容',
        emailImage: '請上傳信件海報',
        footer: '請輸入信件Footer',
        time1: '請選擇時間',
        lastUpdateDate: '最後更新時間',
        selectAccountName: '選擇帳戶名稱',
        selectProductName: '選擇產品名稱',
        selectCampaignName: '選擇活動名稱',
        onlineDate: '在線日期',
        order: '命令',
        adSpendBudget: '廣告費用預算',
        adSpend: '廣告費用',
        atv: 'ATV',
        enterLandingPage: '進入網站到達頁',
        enterOfferPage: '進入Offer頁',
        impCost: 'Imp Cost',
        merchandise: 'Merchandise',
        numberOfProduct: '產品數量',
        numberOfOrders: '訂單數量',
        logisticFee: '貨運費用',
        productPrice: '產品價格',
        startPurchase: '開始購買',
        avgCTR: '平均CTR',
        vimpCost: 'vimpCost',
        prodcutName: '產品名稱',
        totalAmount: '總金額',
        disputeAmount: '申訴金額',
        disputeRate: '申訴率',
        disputeRate1: '申訴率（依據申訴日期）',
        disputeRate2: '申訴率（依據收費日期）',
        paymentCount: '付款次數',
        disputeCount: '申訴次數',
        disputeCount1: '重複申訴次數',
        disputeCount2: '欺詐糾紛次數',
        disputeCount3: '一般申訴次數',
        disputeCount4: '信用未處理申訴次數',
        notRecCount: '未收到貨物申訴次數',
        productUnacceptableCount: '產品退貨次數',
        subscriptionCanceledDisputeCount: '取消訂閱次數',
        unrecognizedDisputeCount: '無法識別次數',
        notReceivedDisputeAmount: '未收到申訴金額',
        notAsDescribedDisputeAmount: '與說明不符申訴金額',
        unauthorizedDisputeAmount: '未授權申訴金額',
        creditNotProcessedDisputeAmount: '信用未處理申訴金額',
        duplicateTransactionDisputeAmount: '重複申訴金額',
        incorrectAmountDisputeAmount: '金額錯誤申訴金額',
        paymentByOtherMeansDisputeAmount: '其他付款方式申訴金額',
        canceledRecurringBillingDisputeAmount: '已取消的定期帳單申訴金額',
        problemWithRemittanceAmount: '匯款金額問題',
        otherDisputeAmount: '其他申訴金額',
        fraudAmount: '欺詐糾紛金額',
        fraudRate: '欺詐糾紛率',
        // 需要翻译
        comparison1d: '1d对比',
        comparison7d: '7d对比',
        comparisons: '周期对比'
    },
    serviceRecord: {
        tracker: 'Tracker',
        backgroundInformation: '背景資訊',
        edit: '編輯',
        accountName: '帳戶名稱',
        accountHoldersEmail: '開戶人信箱',
        accountTimeZone: '帳戶時區',
        // country: '國家',
        country: '地區',
        industryCategory: '行業分類',
        accountManager: 'AM負責人',
        businessManager: '商務經理',
        coreTarget: '核心目標',
        launchProduct: '投放產品',
        communicationChannel: '溝通渠道',
        otherInformation: '其他訊息',
        serviceRecord: '服務紀錄',
        serviceType: '服務類型',
        deliveryStatus: '投放動態',
        meetingCommunication: '會議/溝通',
        changeOfManager: '接口人變更',
        other: '其他',
        timeline: '時間軸',
        operationRecordDescription: '操作紀錄說明',
        add: '添加',
        config: 'Account維度的配置只對新創建的campaign生效。舊的campaign的白名單保存不變。',
        whitelist: '白名單',
        individualChoice: '可多選',
        supportbatch: '支持批量寫入，使用;分隔',
        percentageOfMedia: '媒體佔比',
        pleaseenter: '請輸入1~99，數字為該活動在媒體可占比的點擊比例（單位%）',
        clientid: '客户ID:',
        clientSecret: '客户机密:',
        accountid: '帳戶ID:',
        added1: '已新增:',
        profitMarginOperation: '利潤率運營',
        profitMargin: '利潤率',
        replenishmentday: '補量天級虧損上限',
        topUptotallosscap: '補量總虧損上限',
        trainingOperations: '訓練運營',
        speedUpTrainingMultiple: '加速訓練倍數',
        retrainingthreshold: '重新訓練閥度值',
        cVROptimization: 'CVR優化',
        switch: '開關'
    },
    tracker: {
        trackerCategory: '事項類別',
        priority: '優先順序',
        status: '狀態',
        industryCategory: '產業分類',
        onlyShow: '僅篩選「自己負責項目」',
        include: '包含歷史責任',
        afterchecking: '勾選後，則只能搜尋負責人，無法同時搜尋帳戶',
        newItem: '新建事項',
        ordinal: '序號',
        adAccount: '廣告帳號',
        updateTime: '更新時間',
        accountManager: 'AM負責人',
        currentManager: '現任負責人',
        creationTime: '建立時間',
        operate: '操作',
        update: '事項更新',
        consumptionRiseAnalysis: '消耗上升分析',
        consumptionFallAnalysis: '消耗下降分析',
        lowBudget: '預算消耗不掉',
        bugFeedback: '平台bug反饋',
        functionOptimization: '功能優化建議',
        material: '素材設計需求',
        strategicSupport: '策略傾斜給量',
        technicalSupport: '技術支持',
        conversionRelated: '轉換相關',
        other: '其他',
        request: '請求數',
        callback: '召回數',
        imp: '展示數',
        vimp: '可視展示數',
        click: '點擊',
        revenue: '收入',
        selectPublisher: '選擇媒體',
        selectPage: '選擇頁面',
        selectFormat: '選擇格式',
        selectAccount: '選擇賬戶',
        selectCampaign: '選擇廣告計劃',
        search: '搜索',
        reset: '重置',
        byPublisher: '按出版商',
        byPage: '按頁面',
        byFormat: '按格式',
        byAccount: '按帳戶',
        byCampaign: '按活動',
        more: '更多',
        columns: '列',
        publisherName: '媒體名稱',
        page: '頁面',
        format: '格式',
        accountName: '帳戶名稱',
        campaignName: '廣告計劃名稱',
        callbackRate: '召回率',
        winRate: '競得率',
        viewableRate: '可視率',
        iCTR: '展示點擊率',
        vCTR: '可視點擊率',
        callbackRateROC: '召回率變動速率',
        winRateROC: '競得率變動速率',
        viewableRateROC: '可見率變動速率',
        vCTRROC: '可視點擊變動速率',
        iCTRROC: '展示點擊變動速率',
        cost: '成本',
        rOI: '投資回報率',
        icpm: 'icpm',
        cRpm: 'cRpm',
        iRpm: 'iRpm',
        revenueROC: '收入變動速率',
        revenueDiff: '收入差異',
        requestDiff: '請求差異',
        callbackDiff: '召回差異',
        impDiff: '展示差異',
        vimpDiff: '可視展示差異',
        clickDiff: '點擊差異',
        profit: '利潤',
        profitDiff: '利潤差異',
        accountID: '賬戶ID',
        campaignID: '廣告計劃 ID',
        accountNameData: 'accountNameData',
        conversion: '轉換',
        conversionDiff: '轉換差異',
        conversionRate: '轉換率',
        conversionRateROC: '轉換率變動速率',
        costPerConversion: '轉換成本',
        costPerConversionDiff: '轉換成本差異',
        viewContent: '查看內容',
        appInstall: '安裝應用',
        completeRegistration: '完成註冊',
        addToCar: '加入購物車',
        addPaymentInfo: '添加付款信息',
        startCheckout: '開始結帳',
        purchase: '購買',
        addToWishlist: '加入願望清單',
        lead: '潛在用戶',
        submissionDate: '素材提交時間',
        reviewDate: '審核日期',
        startDate: '開始時間',
        endDate: '結束時間',
        geoEdgeAlert: 'GeoEdge報警'
    },
    monthlyBill: {
        downloadTemplate: '下载模板',
        initData: '原始數據',
        currentData: '當前數據',
        operationLog: '操作記錄',
        placeholderTip: 'Company Name/user名稱搜索',
        uploadErrorTip: '上傳失敗，請修改後重新上傳',
        uploadTip: '僅支持可編輯部分上傳',
        uploadTypeTip: '僅支持 csv 格式',
        selectFlie: '選擇文件',
        uploadResult: '上传結果',
        editTip: ' 修改了 ',
        editFormTip: ' 由 ',
        editToTip: ' 改為 ',
        remark: '備註',
        upload: '導入',
        line: '第'
    },
    // AI-CreativeGo
    aiCreativeGo: {
        Japan: '日本',
        twd: '台灣',
        hk: '香港',
        my: '馬來西亞',
        Japanese: '日語',
        chinese: '中文（簡體）',
        zhHant: '中文（繁體）',
        // title
        hearderTitle: 'AI自動生成廣告標題',
        contentTitle: 'AI生成標題',
        // form-labels
        landingPage: '廣告連結網址',
        location: '地區',
        language: '語言',
        sellingPoint: '產品特色',
        titleSample: '標題範例',
        audience: '目標受眾',
        gender: '性別',
        age: '年齡',
        productName: '產品名稱',
        campaignCategory: '廣告類別',
        // btns
        createBtn: '產生新標題',
        copyAllBtn: '複製全部標題',
        // tips
        landingPageNoneTip: '請填寫廣告連結網址',
        landingPageRuleTip: '落地页链接输入框不支持包含<>',
        languageNoneTip: '请选择一种语言',
        sellingPointTip: '支持批量写入，使用英文,分隔',
        titleSampleTip: '请提供3个标题供popIn Creative Maker学习，每个标题1行',
        copied: '复制成功',
        requestErrorTip: '页面繁忙，请稍后再试',
        all: '不限',
        // 目标受众-option:
        officeWorkers: '上班族',
        Homemakers: '家庭主婦',
        elderlyPeople: '中老年人',
        sportsEnthusiasts: '運動愛好者',
        investors: '投資者',
        entrepreneurs: '創業者',
        artistsAndDesigners: '藝術家和設計師',
        technologyEnthusiasts: '科技愛好者',
        travelEnthusiasts: '旅行愛好者',
        // gender-option:
        male: '男性',
        female: '女性',
        // age-option:
        ageRange1: '18歲以下',
        ageRange2: '18到24歲',
        ageRange3: '24到44歲',
        ageRange4: '45到64歲',
        ageRange5: '65到85歲',
        ageRange6: '85歲以上',
        // campaignCategory-option
        // aiCreativeGo下所对应的日文较constants的更为准确
        autoselectronics: '汽車與電子類',
        business: '商業類',
        beautyFashion: '美容與時尚類',
        entertainment: '娛樂類',
        economyMoney: '經濟與金融類',
        election: '選舉類',
        education: '教育類',
        finance: '財務金融類',
        foodDrink: '食物和飲品類',
        homepage: '首頁類',
        health: '健康類',
        homeLifestyle: '家居與生活類',
        loveMarriage: '愛情與婚姻類',
        music: '音樂類',
        movies: '電影類',
        news: '新聞類',
        politicsSocial: '政治與社會類',
        sports: '運動類',
        travel: '旅行類',
        TV: '電視類',
        video: '影音類',
        weather: '天氣類'
    },
    ...state,
    ...country
};
