/**
 * @file 结构文件
 * @author  fangsimin
 * @date    2018-12-21 10:46:18
 * @last Modified by    fangsimin
 * @last Modified time  2020-03-01 13:04:42
 */

<template>
    <el-container
        v-loading="loading" :class="!showMenu ? 'no-permission' : ''"
        element-loading-spinner="el-icon-loading"
    >
        <el-aside
            class="aside" :class="{'collapse': asideStatus , 'expand': !asideStatus}"
        >
            <div class="menu-box">
                <div class="logo logo-bg" :class="{'m-logo': asideStatus, discovery: isDiscovery}"></div>
                <aside-menu
                    :data="menuData" :base="base"
                    :is-collapse="asideStatus"
                />
            </div>
            <div class="aside-ctrl" @click="handleAsideCtrlClick">
                <div class="aside-ctrl-icon" :class="{'aside-ctrl-icon_reversed': asideStatus}"></div>
            </div>
        </el-aside>
        <el-container>
            <el-header class="head-ele" style="height: 56px">
                <div class="header-title">
                    <el-breadcrumb
                        v-if="!currentPath.includes('apply')" class="breadcrumb"
                        separator-class="el-icon-arrow-right"
                    >
                        <template v-for="(list, index) in currentPath">
                            <el-breadcrumb-item
                                v-if="BREADCRUMBLINKS.includes(list)" :key="index"
                                :to="{path: PATHS[list]}"
                            >
                                {{ renderBreadCrumbTitle(list) }}
                            </el-breadcrumb-item>
                            <el-breadcrumb-item v-else :key="index">
                                {{ renderBreadCrumbTitle(list) }}
                            </el-breadcrumb-item>
                        </template>
                    </el-breadcrumb>
                    <div v-else class="login-logo np-logo"></div>
                </div>
                <!-- 有用户信息时，才展示 -->
                <div v-if="userName || userId" class="topBar">
                    <!-- <div class="lang-container_noFixd lang-container">
                        <span :class="curLang === 'cn' ? 'active' : ''" @click="handleCommand('zh_CN')">中文</span>/
                        <span :class="curLang === 'en' ? 'active' : ''" @click="handleCommand('en_US')">English</span>
                    </div> -->
                    <div class="userWrap">
                        <div class="userName">
                            <!-- <div class="user-account" v-if="+role === 2"> -->
                            <div v-if="hasPermissionAccount && hasPermissionAccount.length" class="user-account">
                                <i class="msn-fa msn-fa-balance user-balance-icon"></i>
                                <span class="user-balance" style="font-size: 12px">
                                    {{ $t("msn.menuData.balance") }}
                                    <msn-tip
                                        width="390"
                                        :content="$t('msn.dashboard.totalBalanceTip')"
                                    />:&nbsp;&nbsp;
                                <!-- <span :class="`${currentWriteableBalance < minLimit ? 'danger' : 'success'}`"> {{ currencySymbol }}{{ currentWriteableBalance | numberFormat }}</span> -->
                                </span>
                                <!-- 恢复下拉显示多账户 -->
                                <template v-if="currentAccount">
                                    <div class="account-balance-container">
                                        <!-- <template v-if="+currentAccount.balance < minLimit">
                                            <el-tooltip
                                                popper-class="msn-tool-tip" effect="gray"
                                                :offset="-40" :content="$t('msn.creditLine.balanceNote1')"
                                                placement="bottom"
                                            >
                                                <i class="msn-fa msn-fa-danger"></i>
                                            </el-tooltip>
                                        </template>
                                        <template v-else>
                                            <i class="msn-fa msn-fa-balance"></i>
                                        </template> -->
                                        <span :class="`${+currentAccount.balance < minLimit ? 'danger' : 'success'}`">&nbsp;&nbsp;{{ currencySymbol }}{{ currentAccount.balance | numberFormat }}</span>
                                    </div>
                                    {{ currentAccount.label }}
                                </template>
                                <template v-else>
                                    <el-dropdown
                                        :hide-on-click="false" trigger="click"
                                        @visible-change="showOrHideIcon"
                                        @command="handleAccountDropdown"
                                    >
                                        <span class="el-dropdown-link" style="font-size: 12px; color: #2dd1ac">
                                            ({{ accountNums }})&nbsp;&nbsp;
                                            <span :class="`${currentWriteableBalance < minLimit ? 'danger' : 'success'}`">{{ currencySymbol }}{{ currentWriteableBalance | numberFormat }}</span>
                                            <i
                                                style class="msn-fa"
                                                :class="`${isShowDropdownIcon ? 'msn-fa-account-arrow-up' : 'msn-fa-account-arrow-down'}`"
                                            ></i>
                                        </span>
                                        <el-dropdown-menu
                                            slot="dropdown" placement="bottom-start"
                                            class="dropdown-style"
                                        >
                                            <el-dropdown-item
                                                v-for="item in hasPermissionAccount" :key="item.label"
                                                :command="item"
                                            >
                                                <span class="account-name">{{ item.label }}</span>
                                                <span class="balance-text" :class="`${+item.balanceStatus === 1 ? 'success-text' : 'danger-text'}`">
                                                    <el-popover
                                                        v-if="filterList.includes(item.balanceStatus)"
                                                        placement="bottom-start"
                                                        trigger="hover"
                                                        width="280"
                                                    >
                                                        <!--bca-disable-->
                                                        <div
                                                            v-html="totalBalanceTip(item)"
                                                        ></div>
                                                        <!--bca-disable-->
                                                        <span slot="reference" style="font-size:12px;cursor: pointer;">
                                                            <i :class="`msn-fa ${balanceIcon(+item.balanceStatus)}`"></i>
                                                        </span>
                                                    </el-popover>
                                                    {{ currencySymbol }}
                                                    {{ item.balance || 0 | numberFormat }}
                                                </span>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </div>
                        </div>
                        <!-- 头部新增币种选择 -->
                        <div v-if="showCurrencyOption" class="currency-box">
                            <msn-select
                                v-model="currency"
                                default-first-option
                                class="msn-currency"
                                :option-list="currencyList"
                                @change="changeCurrency"
                            />
                        </div>
                        <!-- 头部新增语言选择 -->
                        <div class="language">
                            <el-dropdown trigger="click" @visible-change="showLanguageIcon">
                                <i class="msn-fa-earth"></i>
                                <span class="el-dropdown-link">
                                    {{ language === 'zhHant' ? 'TW' : language }}
                                    <i style="font-size: 14px; padding-top: 1px" :class="`${isShowLanguageIcon ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}`"></i>
                                </span>
                                <el-dropdown-menu
                                    slot="dropdown" class="dropdown-style"
                                    style="text-align: left"
                                >
                                    <div class="">
                                        <el-dropdown-item
                                            v-for="item in languageList" :key="item.value"
                                            class="dropdown-item" @click.native="languageEvent(item.value)"
                                        >
                                            <span>{{ item.label }}</span>
                                            <i v-if="item.value.toLowerCase() === language.toLowerCase()" class="el-icon-check"></i>
                                        </el-dropdown-item>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <!-- 新dashboard新增国家选择 -->
                        <div v-if="showCountryComponent" class="currency-box country-wrapper">
                            <el-cascader
                                v-model="country"
                                :props="{expandTrigger: 'hover'}"
                                class="msn-currency" :options="countrySelectList"
                                :show-all-levels="false"
                                popper-class="country-select-style"
                                @change="changeCountry"
                            />
                        </div>
                        <!-- <el-tooltip
                            popper-class="msn-tool-tip" effect="gray"
                            :content="$t('message.button.logout')" placement="bottom-start"
                        >
                            <div :class="`msn-fa msn-fa-${showMenu ? 'logout' : 'logout-w'} logout-btn`" @click="loginOut"></div>
                        </el-tooltip> -->
                        <message-center
                            ref="message"
                            :lang="curLang"
                        />
                        <user-popover/>
                    </div>
                </div>
                <div v-else class="topBar">
                    <div class="language">
                        <el-dropdown trigger="click" @visible-change="showLanguageIcon">
                            <i class="msn-fa-earth"></i>
                            <span class="el-dropdown-link">
                                {{ language === 'zhHant' ? 'TW' : language }}
                                <i style="font-size: 14px; padding-top: 1px" :class="`${isShowLanguageIcon ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}`"></i>
                            </span>
                            <el-dropdown-menu
                                slot="dropdown" class="dropdown-style"
                                style="text-align: left"
                            >
                                <div class="">
                                    <el-dropdown-item
                                        v-for="item in languageList" :key="item.value"
                                        class="dropdown-item" @click.native="languageEvent(item.value)"
                                    >
                                        <span>{{ item.label }}</span>
                                        <i v-if="item.value.toLowerCase() === language.toLowerCase()" class="el-icon-check"></i>
                                    </el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <el-tooltip
                        popper-class="msn-tool-tip" effect="gray"
                        :content="$t('message.button.login')" placement="bottom-start"
                    >
                        <a :href="PATHS['login']" class="item-link msn-fa msn-fa-login"></a>
                    </el-tooltip>
                </div>
            </el-header>
            <el-alert v-if="false" type="warning">
                <p class="alert-more">
                    {{ $t('msn.menuData.uploadWaring') }}<span @click="handleLearnMore">{{ $t('msn.menuData.uploadWaringMore') }}</span>
                </p>
            </el-alert>
            <msn-dialog
                style="text-align:left"
                :visible.sync="moreVisible"
                width="580px"
                :title="$t('msn.button.deletePage')"
                :before-close="handleClose"
            >
                <!--bca-disable-->
                <p
                    style="padding:50px; padding-top:10px; text-align: left;word-wrap: break-word;word-break: normal;white-space: pre-wrap" v-html="$t('msn.menuData.uploadText')"
                >
                </p>
                <div slot="footer" class="footer-container">
                    <el-button @click="handleClose">
                        {{ $t('msn.button.cancel') }}
                    </el-button>
                    <el-button type="primary" @click="handleClose">
                        {{ $t('msn.button.confirm') }}
                    </el-button>
                </div>
            </msn-dialog>

            <msn-global-notify ref="msnGlobalNotify"/>
            <el-main class="msn-main" style="padding-top: 0">
                <keep-alive :include="keepAliveComp">
                    <router-view v-if="isRouterAlive"/>
                </keep-alive>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
// 左边菜单
import Cookie from 'js-cookie';
import MsnDialog from '@/components/MsnDialog';
import pathConfig from './constants/pathConfig';
import asideMenu from '@/modules/msn/components/Menu';
import MsnGlobalNotify from '@/components/MsnGlobalNotify';
import tool from './common/tool';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import api from '@/constants/api';
import { HELP_CENTER } from '@/constants/helpCenterPath';
import constants from '@/constants';
import { MODULE_PLATFORM, newCurrencyList } from '@/modules/msn/constants';
import commonFiters from '@/common/filters';
import utils from '@/common/utils';
import MsnSelect from '@/components/MsnSelect/Select';
import { currencyList, minLimitList, filterList} from './constant';
import { languageList } from '@/constants/language.js';
// import { countryList } from '@/constants/countryList.js';
import PATH from '@/constants/path';
import userPopover from './userPopover.vue';
import messageCenter from './messageCenter.vue';
import {balanceMap} from './pages/new_dashboard/balanceTip/consts';

const PATHS = constants.path;
const BREADCRUMBLINKS = constants.breadcrumbLinks;

const moduleName = MODULE_PLATFORM;
const queryPS = 5 * 60 * 1000; // 获取当前账户信息的轮询间隔
export default {
    // inject: ['localReload'],
    name: 'App',
    components: {
        'msn-select': MsnSelect,
        asideMenu,
        MsnGlobalNotify,
        'user-popover': userPopover,
        messageCenter,
        MsnDialog
    },
    // provide (){
    //     return {
    //         localReload: this.localReload
    //     };
    // },
    data: function () {
        return {
            writeAccountNums: '',
            writeAccountList: [],
            countrySelectList: [],
            PATH,
            base: moduleName,
            pathConfig: pathConfig,
            loading: false,
            // menuData: [],
            queryAccountInfoTId: null,
            PATHS,
            BREADCRUMBLINKS,
            isShowDropdownIcon: false,
            isShowLanguageIcon: false,
            asideStatus: false, // 侧边栏收缩状态 true->折叠 false->展开
            languageList,
            currencyList,
            minLimitList,
            language: '',
            country: '',
            currency: '',
            isRouterAlive: true,
            newCurrencyList,
            moreVisible: false,
            balanceMap,
            filterList,
            getAccountListDebounce: null
        };
    },
    mounted(){
        this.getWriteAccountList();
        const dpr = document.getElementsByTagName('html')[0].getAttribute('data-dpr') || 1; // 因为flexible.js需要设置dpr
        this.asideStatus = window.innerWidth / (+dpr) < 768;
        // 设置语言
        this.handleSetLanguage();
        // 设置国家
        this.handleSetCountry();
        // 设置币种
        this.handleCurrency();
        // 上报用户信息
        this.$GaReport.reportUserInfo();
    },
    computed: {
        ...mapGetters('common', {
            curCountry: 'getCountry',
            curLang: 'getLang',
            isDiscovery: 'getIsDiscovery',
            getCurrencyInfo: 'getCurrencyInfo',
            isCurrencySymbol: 'getCurrencySymbol',
            getCurrency: 'getCurrency'
        }),
        ...mapGetters('msn', {
            userName: 'getUserName',
            userId: 'getUserId',
            role: 'getRole',
            accountList: 'getAccountList',
            showFeedback: 'getShowFeedback',
            accountInfo: 'getAccountInfo',
            keepAliveComp: 'getKeepAliveComp',
            menu: 'getMenu',
            lastOperateInfo: 'getLastOperateInfo'   // 用户上次操作信息
        }),
        // 没权限则不展示左侧菜单
        showMenu() {
            const routePath = this.$route && this.$route.path;
            const path = routePath && routePath.split('/').slice(-1)[0];
            return path !== 'apply';
        },
        currentPath() {
            let path = this.$route.path;
            return path.split('/').slice(2);
        },
        currentWriteableBalance() {
            return this.hasPermissionAccount
                && this.hasPermissionAccount.length
                ? this.hasPermissionAccount.map(item => item.balance || 0).reduce((pre, next) => pre + next, 0) : 0;
        },

        hasPermissionAccount() {
             // 可写账户切到了accountinfo接口，这里需要更新
            const returnList = this.accountInfo.accountList && this.accountInfo.accountList.length ? this.accountInfo.accountList : [];
            // 通知余额不足s
            this.$nextTick(() => {
                const accountList = returnList;
                if (!accountList || accountList.length === 0) {
                    return;
                }
                let totalMoney = 0;
                accountList.forEach(item => {
                    totalMoney += isNaN(parseFloat(item.balance)) ? 0 : parseFloat(item.balance);
                });
                const formatTotalMoney = '$' + commonFiters.numberFormat(totalMoney);
                const tipT = {
                    en: [`Your total balance is ${formatTotalMoney}. Visit {Billing} to recharge your account to continue advertising.`,
                         `Your total balance is ${formatTotalMoney}. Visit {Billing} to recharge your account before launching your first campaign.`, `Your total balance is ${formatTotalMoney}. Visit {Billing} to recharge your accounts to continue advertising.`],
                    cn: [`您的总可用额度为${totalMoney}，请前往{账户充值}页面充值以继续投放广告。`, `您的总可用额度为${formatTotalMoney}，
                        请前往{账户充值}页面充值以投放您的第一条广告。`, `您的总可用额度为${formatTotalMoney}，请前往{账户充值}页面充值以继续投放广告。`]
                };
                const useT = tipT[this.curLang];
                // 测试
                // totalMoney = -100;
                // 所有账户总可用额度<=0
                if (totalMoney > 0) {
                    this.$refs.msnGlobalNotify.close();
                } else {
                    const opt = {
                        goto: '/platform/recharge'
                    };
                    // 用户账户数量=1
                    if (accountList.length === 1) {
                        // yes
                        // 账户下是否有campaign
                        const curAccount = accountList[0];
                        if (parseInt(curAccount.campaign) === 0) {
                            // no
                            opt.msg = useT[1];
                        } else {
                            // yes
                            opt.msg = useT[0];
                        }
                    } else {
                        // no
                        opt.msg = useT[2];
                    }
                    this.$refs.msnGlobalNotify.open(opt);
                }
            });
            // 通知余额不足e
            return returnList;
        },
        currentAccount() {
            let account = null;
            if (this.accountList && this.accountList.length === 1) {
                account = this.accountList[0];
            }
            console.log(account, 'account');
            return account;
        },
        menuData() {
            const path = this.pathConfig['menu'];
            let res = [];
            path.forEach(p => {
                let curMenu = {};
                if (tool.includePermission(p.path, 'msn')) {
                    curMenu = p;
                    if (p.children && p.children.length > 0) {
                        curMenu.children = p.children.filter(pc => tool.includePermission(pc.path, 'msn'));
                    }
                    res.push(curMenu);
                }
            });
            return res;
        },
        accountNums() {
            let len = this.hasPermissionAccount.length;
            let accountUnit = len > 1 ? this.$t('msn.menuData.accounts') : this.$t('msn.menuData.account');
            return `${len} ${accountUnit}`;
        },
        showCurrencyOption(){
            let noPermission = this.menu[PATH['currencyType']] === 'rw';
            if(noPermission){
                return noPermission;
            } else {
                // 没权限删除cookie
                Cookie.remove('currency');
                return noPermission;
            }
        },
        currencySymbol(){
            const currency = this.newCurrencyList.find(currency => currency.value === this.getCurrency);
            console.log(this.getCurrencyInfo.currency);
            return currency ? currency.symbol : '$';
        },
        minLimit(){
            let res;
            res = minLimitList.find(i => i.currency === this.getCurrency);
            console.log(res);
            return res ? res.value : 200;
        },
        showCountryComponent() {
            console.log(this.currentPath[0] === 'new_dashboard' || this.currentPath[1]=== 'amConfig');
            const funcPermission = this.menu[PATH['new_dashboardCountry']] === 'rw';
            const isTarget = this.currentPath && (this.currentPath[0] === 'new_dashboard' || this.currentPath[1]=== 'amConfig');
            return isTarget && funcPermission;
        }
    },
    methods: {
        ...mapMutations('common', {
            switchLang: 'switchLang',
            setCountry: 'setCountry',
            setHelpCenterLang: 'setHelpCenterLang',
            switchCurrency: 'switchCurrency'
        }),
        ...mapMutations('msn', {
            setUserAccount: 'SET_USER_ACCOUNT'
        }),
        ...mapActions('msn', {
            setAccountInfo: 'setAccountInfo',
            setMenu: 'setMenu',
            setUserLastOperationalInfo: 'setUserLastOperationalInfo'
        }),
        balanceIcon(status){
            if(status === 1 || status <= 0) return '';
            return this.balanceMap[+status].icon;
        },
        totalBalanceTip(row){
            const {usableDays, balanceStatus} = row;
            if(+balanceStatus === 1 || +balanceStatus <= 0) return '';
            return this.$t(this.balanceMap[balanceStatus].tip, {num: usableDays});
        },
        handleSetLanguage() {
            // lastOperateInfo.lang :数据库的lang
            const storeLang = this.lastOperateInfo.lang || localStorage.getItem('lang');
            const currentLangInfo = languageList.find(item => storeLang === item.value);
            let language = currentLangInfo ? currentLangInfo.value : 'zh';
            this.languageEvent(language);
        },
        handleSetCountry() {
            const hasPermission = this.menu[PATH['new_dashboardCountry']] === 'rw';
            if (hasPermission) {
                this.getCountryList();
                try {
                    if(this.lastOperateInfo.country) {
                        // 先取数据库的country更新至本地缓存
                        const country = JSON.parse(this.lastOperateInfo.country);
                        localStorage.setItem('new_country', country.join(','));
                    }
                } catch (error) {}

                const localCountry = localStorage.getItem('new_country');
                if(localCountry) {
                    const localList = localCountry.split(',');
                    this.country = (localList.length === 1) ? ['ALL', 'ALL'] : localList;
                    return;
                }
            }
            this.country = ['ALL', 'ALL'];
            this.setCountry(['ALL', 'ALL']);
        },
        getWriteAccountList() {
             this.$request({
                method: 'get',
                url: api.writeAccountList,
                success(res) {
                    this.writeAccountNums = res.data.accountNum;
                    this.writeAccountList = res.data.accountList;
                },
                error() {
                }
            });
        },
        handleAccountDropdown(account) {
            console.log(account);
            const {label, value} = account;
            const {path} = this.$route;
            if(path.indexOf('new_dashboard') <0 ) {
                this.$router.push({ path: PATH['new_dashboard']});
            }
            this.setUserAccount({accountId: value, accountName: label});
        },
        // 点击了解详情弹窗
        handleLearnMore() {
            this.moreVisible = true;
        },
        // 关闭弹窗
        handleClose() {
            this.moreVisible = false;
        },
        // 处理多货币筛选局部刷新
        localReload(){
            this.isRouterAlive = false;
            this.$nextTick(()=>{
                this.isRouterAlive = true;
            });
        },
        showOrHideIcon(val) {
            if (val){
                this.getAccountListDebounce();
            }
            this.isShowDropdownIcon = val;
        },
        showLanguageIcon(val) {
            this.isShowLanguageIcon = val;
        },
        // handleCommand(command) {
        //     const showConfirm = this.currentPath.includes('campaign');
        //     const permission = this.currentPath.includes('apply') && !this.showFeedback;
        //     if (showConfirm) {
        //         localStorage.setItem('lang', command.startsWith('zh') ? 'cn' : (command.startsWith('en') ? 'en' : 'jp'));
        //         location.reload();
        //         // this.$confirm(this.$t('msn.validations.switchLang'), this.$t('msn.infos.info'), {
        //         //     type: 'warning',
        //         //     customClass: 'msn-message-box',
        //         //     showClose: false,
        //         //     cancelButtonText: this.$t('msn.button.cancel'),
        //         //     confirmButtonText: this.$t('msn.button.confirm'),
        //         //     cancelButtonClass: 'msn-cancel-button',
        //         //     confirmButtonClass: 'msn-confirm-button'
        //         // })
        //         //     .then(_ => {
        //         //         localStorage.setItem('lang', command.startsWith('zh') ? 'cn' : 'en');
        //         //         location.reload();
        //         //     })
        //         //     .catch(_ => {});
        //     } else if (permission) {
        //         // 注册页面不进行确认，直接刷新
        //         localStorage.setItem('lang', command.startsWith('zh') ? 'cn' : (command.startsWith('en') ? 'en' : 'jp'));
        //         location.reload();
        //     } else {
        //         this.switchLang(command);
        //     }
        // },
        loginOut() {
            this.loading = true;
            this.$request({
                method: 'get',
                url: api.logout,
                success(res) {
                    this.loading = false;
                    this.$message.success(this.$t('msn.requestMsg.logoutSuccess'));
                    location.href = PATHS['login'];
                },
                error() {
                    this.loading = false;
                    this.$message.error(this.$t('msn.requestMsg.logoutFailed'));
                }
            });
        },
        renderBreadCrumbTitle(title) {
            if (title === 'conversions') {
                return this.$t('msn.menuData.conversions');
            }
            return this.$t(`msn.menuData.${title}Title`);
        },
        // 处理收缩侧边栏
        handleAsideCtrlClick() {
            this.asideStatus = !this.asideStatus;
            let timeOut = setTimeout(() => {
                // window.onresize && window.onresize();
                let event = document.createEvent('HTMLEvents');
                event.initEvent('resize', true, true);
                window.dispatchEvent(event);
                clearTimeout(timeOut);
            }, 300);
        },
        changeCountry(e) {
            // 存数据库
            this.setUserLastOperationalInfo({ country: JSON.stringify(this.country) });
            // 存本地
            localStorage.setItem('new_country', this.country);
            this.setCountry(this.country);
        },
        // 语言显示以及参数处理
        languageEvent(e){
            const langMap = {
                zh: {language: 'ZH', locale: 'cn'},
                zhHant: {language: 'zhHant', locale: 'zhHant'},
                ja: {language: 'JA', locale: 'ja'},
                en: {language: 'EN', locale: 'en'},
                ko: {language: 'KO', locale: 'ko'}
            };
            const {language, locale} = langMap[e];
            this.language = language;
            localStorage.setItem('lang', e);
            this.$i18n.locale = locale;
            const lang = this.$i18n.locale;
            this.switchLang(lang);
            this.setHelpCenterLang(lang);
            // 存数据库
            this.setUserLastOperationalInfo({ lang: e });
        },
        changeCurrency(){
            this.switchCurrency(this.currency);
            this.setMenu(true);
            this.getWriteAccountList();
            // 存数据库
            this.setUserLastOperationalInfo({ currency: this.currency });
            this.getAccountListDebounce();
            // if(window.location.href.indexOf('/platform/campaign') > -1) {
            //     return;
            // }
            // // location.reload();
            // this.localReload();
        },
        handleCurrencyCookie(){
            let str = Cookie.get('currency');
            this.currencyList.forEach(item =>{
                if(item.value == str){
                    this.currency = this.$t(item.label);
                };
            });
        },
        // 判断账户币种个数，若大于1，则默认USD
        handleCurrency() {
            if(this.lastOperateInfo.currency) {
                // 先将数据库的currency更新至本地缓存
                Cookie.set('currency', this.lastOperateInfo.currency, {expires: 10000});
            }
            const hasCookie = Cookie.get('currency');
            if(!hasCookie){
                const res = this.getCurrencyInfo;
                Cookie.set('currency', res.currency, {expires: 10000});
                this.$set(this, 'currency', res.currency);
            }else{
                this.switchCurrency(hasCookie);
                this.currency = hasCookie;
            }
        },
        handleMultipleAccounts(e){
            return utils.currencySymbolFormat(e);
        },
        getCountryList() {
            this.$request({
                method: 'get',
                url: api.getCountryList,
                success(res) {
                    this.countrySelectList = res.data;
                },
                error() {

                }
            });
        }
    },
    async created() {
        // 当用户没有登录，同时当前页面是help center中的页面时，不获取账户信息
        const menuKeys = Object.keys(this.menu || {});
        const helpCenterKeys = Object.keys(HELP_CENTER || {});
        if (!(HELP_CENTER[this.$route.path] && menuKeys.length === helpCenterKeys.length + 1)) {
            await this.setAccountInfo();
            this.queryAccountInfoTId = setInterval(() => {
                this.setAccountInfo();
            }, queryPS);
        }
        this.getAccountListDebounce = utils.debounce(() => {
            this.setAccountInfo();
        }, 1000);
    },
    beforeDestroy() {
        if (this.queryAccountInfoTId) {
            clearInterval(this.queryAccountInfoTId);
        }
    },
    watch: {
        curLang: {
            immediate: true,
            handler(val) {
                this.$i18n.locale = val;
                this.switchLang(val);
                this.setHelpCenterLang(val);
            }
        }
    }
};
</script>

<style lang="scss">
// 日期时间选择器隐藏分
.date-time-picker-delete-minutes {
    .el-scrollbar:nth-of-type(2) {
        display: none !important;
    }
    .el-time-spinner {
        text-align: center;
    }
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
textarea,
input,
button,
select {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
.el-cascader-node.in-active-path {
    color: #2dd1ac!important;
}
.el-cascader-node.is-active  {
    color: #2dd1ac!important;
}
.el-cascader-menu {
    min-width: 150px;
}
.alert-more{
    line-height: 30px;
    color: #fff;
    font-size: 14px;
    span{
        color:#3f93f5;
        cursor: pointer;
        text-decoration: underline;
    }
}
.el-alert--warning.is-light{
    background: #feb01c;
    max-height: 40px;
    min-height: 40px;

}
.el-alert.is-light .el-alert__closebtn {
    color:#fff;
    font-size: 16px;
}
.el-header {
    background-color: #fff;
    color: #53575b;
    line-height: 50px;
    font-size: 12px;
    height: 56px;
    display: flex;
    box-shadow: -3px 2px 2px 0 rgba(119, 151, 178, 0.16);
    z-index: 1;
}

.header-title {
    flex: 1;
    text-align: left;
    font-size: 14px;
    color: #2dd1ac;
    font-weight: 700;
    display: flex;
    align-items: center;
    .np-logo {
        margin-left: 10px;
    }
}

.head-ele .breadcrumb {
    flex: 1;
    line-height: 50px;
    .el-breadcrumb__item:last-child .el-breadcrumb__inner,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
    .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
    .el-breadcrumb__item:first-child .el-breadcrumb__inner,
    .el-breadcrumb__item:first-child .el-breadcrumb__inner:hover,
    .el-breadcrumb__item:first-child .el-breadcrumb__inner a,
    .el-breadcrumb__item:first-child .el-breadcrumb__inner a:hover {
        font-weight: 700 !important;
        color: #2dd1ac !important;
    }
}

.el-aside {
    color: #333;
    background: #4e546c;
    box-shadow: 0 0 12px 0 rgba(113, 113, 113, 0.5);
    font-size: 14px;
    height: calc(100% - 50px);
    overflow: initial;
}

.logo {
    height: 140px;
    font-size: 40px;
    color: #ffffff;
    letter-spacing: 2px;
    line-height: 140px;
    &.discovery{
        background-size: 130%;
        background-image:url('./assets/images/icon/user-logo.png');
    }
}

.el-container {
    height: 100%;
    position: relative;
    background: #eef4f9;
}
.el-main {
    padding-top: 0;
}
#app {
    height: 100%;
}
.el-dropdown-link {
    cursor: pointer;
    i {
        transition: all 0.2s ease-in-out;
        &.msn-fa-account-arrow-down {
            vertical-align: middle;
        }
    }
    .current-select-text {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.el-dropdown-link:hover {
    // color: #409eff;
    // color: inherit !important;
}
.el-cascader .el-input .el-input__inner{
    background: #fff !important;
    border-radius: 17.5px;
    border: 1px solid #ccc !important;
    height: 35px;
    font-weight: 500;
    font-size: 13px;
    color: #53575B;
}
.topBar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #53575b;
    font-weight: 500;
    .item-link {
        font-size: 20px;
        text-decoration: none;
    }
}
.userWrap {
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.margin-right-5 {
    margin-right: 5px;
}
.userName {
    display: flex;
    flex-direction: column;
    text-align: right;
    color: #53575b;
    margin-right: 20px;
    line-height: 1.5;
    .user-container {
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .user-account {
        display: flex;
        align-items: center;
        .account-balance-container {
            margin-right: 8px;
            display: flex;
            align-items: center;
            .msn-fa {
                margin-right: 3px;
                font-size: 15px;
            }
        }
    }
}
.dropdown-style {
    max-width: 275px;
    max-height: 360px;
    overflow: auto;
    text-align: left;
    padding: 5px 0;
    top: 50px !important;
    li {
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        &:hover,
        &:focus {
            color: inherit !important;
            background-color: #eef4f9 !important;
        }
    }
    .account-name {
        display: inline-block;
        vertical-align: middle;
        width: 135px;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 15px;
    }
    .balance-text {
        display: inline-block;
        text-align: right;
        font-size: 12px;
        max-width: 100px;
        i {
            transform: translateY(2px);
        }
        &.success-text {
            color: #2DD1AC;
        }
        &.danger-text {
            color: #e92754;
        }
    }
}
.lang-container {
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    left: 0;
    bottom: -50px;
    z-index: 100;
    color: #fff;
    line-height: 2.5em;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    // height: 50px;
    background: #525974;
    &.discovery{
        height: 64px;
    }
    span {
        cursor: pointer;
        transition: color 0.5s;
        a {
            color: #fff;
            text-decoration: none;
        }
    }
    .active {
        color: #2dd1ac;
    }
}
.logout-btn {
    font-size: 20px;
    cursor: pointer;
}
.lang-container_noFixd {
    display: none;
}
.no-permission {
    .el-aside {
        display: none;
    }
    .el-main {
        padding: 0;
        margin-top: -56px;
    }
    .el-header {
        z-index: 1;
        background: transparent;
        box-shadow: none;
        .el-dropdown,
        .el-dropdown-link,
        .userName {
            color: #fff !important;
            // &:hover {
            //     color: #fff;
            // }
        }
    }
    .lang-container {
        display: block;
        position: static;
        height: 25px;
        line-height: 25px;
        padding: 0 10px;
        width: auto;
        background: none;
        span {
            cursor: pointer;
            transition: color 0.5s;
        }
        .active {
            color: #3f93f5;
        }
    }
}
// 菜单按钮收缩
.menu-box {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar-track-piece {
        background: #d3dce6;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #99a9bf;
        border-radius: 20px;
    }
}
.aside-ctrl {
    position: absolute;
    background-image: url(./assets/images/asideCtrlBg.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 95px;
    width: 12px;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    &-icon {
        width: 0;
        height: 0;
        border-width: 6px 5px 6px 0px;
        border-color: transparent;
        border-right-color: #b3b7c8;
        border-style: solid;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-60%, -50%);
        &_reversed {
            transform: rotate(180deg);
            transform-origin: 25% 25%;
        }
    }
}
.aside {
    position: relative;
    transition: width 0.28s;
    height: 100%;
    &.expand {
        width: 210px !important;
    }
    &.collapse {
        width: 65px !important;
    }
    .aside-munu-actived{

    }
}
.country-select-style {
    .el-select-dropdown__list .el-select-dropdown__item.selected {
        color: #2dd1ac!important;
        font-weight: 500;
    }
}
</style>
<style lang="scss" scoped>
.user-balance{
    font-size: 12px;
    color: #2DD1AC;
}
.user-balance-icon{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}
.user-box{
    /deep/ .el-popover{
        padding: 15px 25px !important;
    }
    .user-portrait{
    width: 35px;
    height: 35px;
    border: 0.5px solid #ccc;
    border-radius: 50%;
        img{
            width: 100%;
            display: block;
            border: none;
        }
    }
    .user-portrait-top{
        display: flex;
        height: 90px !important;
        /deep/ img{
            float: left;
            width: 60px;
            height: 60px;
            display: block;
        }
        div{
            float: left;
        }
    }
}

.terms-box {
    margin-top: 8px;
    transform: scale(0.9);
    span {
        opacity: 0.8;
    }
    span:hover {
        opacity: 1;
    }
}
.currency-box{
    width: 148px;
    /deep/ .el-input__inner{
    background: #fff !important;
    border: 1px solid #ccc !important;
    line-height: 30px !important;
    /deep/ .el-select-dropdown__list{
        width: 148px !important;
    }
    &:hover{
        border: 1px solid #2dd1ac !important;
    }
}
}

.msn-currency{
    box-sizing: border-box;
    margin-right: 12px;
}
.language {
    cursor: pointer;
    margin-right: 10px;
    width: 82px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #ccc;
    border-radius: 17.5px;
    &:focus{
        border: 1px solid #2dd1ac;
    }
    &:hover{
        border: 1px solid #2dd1ac;
    }
    .el-dropdown-link{
        color: #53575B;
        font-size: 12px;
    }
    .el-dropdown{
        width: 82px;
    }
    /deep/ .el-dropdown-menu{
        text-align: right !important;
    }
}
.country-wrapper {
    width: 90px;
}
.el-dropdown-link {
    cursor: pointer;
}
.el-icon-arrow-down {
    font-size: 12px;
}
.el-icon-check{
    margin-left: 10px;
    line-height: 35px;
}
.el-dropdown-menu__item{
    padding: 0 10px !important;
    // width: 116px !important;
    &:hover{
        color: #2dd1ac !important;
    }
}
.dropdown-item{
    display: flex;
    width: 116px !important;
    &.auto-width {
        width: auto !important;
        white-space: nowrap;
    }
}
.msn-fa-earth{
    // box-sizing: border-box;
    display: inline-block;
    margin-bottom: -2px;
    &::before{
      content:"";
      position:absolute;
      width: 82px;
      height: 35px;
      top:0px;
      left:0px;
    }
}
</style>
